import {Box, Stack} from '@mui/material'

type LayoutProps = {
  mapContainerRef: React.RefCallback<Element>
  mapSlot: React.ReactNode
  cardsSlot: React.ReactNode
}

export const MapWithCardsMobileLayout: React.FC<LayoutProps> = ({
  mapSlot,
  cardsSlot,
  mapContainerRef
}) => {
  return (
    <Stack sx={{width: '100%'}}>
      <Box
        ref={mapContainerRef}
        sx={{
          height: '50vh',
          width: '100%',
          position: 'relative'
        }}
      >
        {mapSlot}
      </Box>
      <Box sx={{px: 3}}>{cardsSlot}</Box>
    </Stack>
  )
}
