import {getTranslationKey} from '@hconnect/common/utils'
import {Error404Page as BaseError404Page} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {useTranslationPrefix} from '../../shared/hooks'

type Props = {
  goToLink?: string
  goToCaption?: string
}

export const Error404Page = ({goToLink, goToCaption}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <BaseError404Page
      caption={t(getTranslationKey('errorPage.notFound', hrocPrefix))}
      description={t(getTranslationKey('errorPage.notFoundDescription', hrocPrefix))}
      goToCaption={goToCaption ?? t(getTranslationKey('errorPage.goToHome', hrocPrefix))}
      goToLink={goToLink ?? '/'}
      RouterLink={Link}
    />
  )
}
