import {PermissionType} from '@hconnect/apiclient'
import {Loader} from '@hconnect/uikit/src/lib2'
import {Navigate, useLocation, useParams} from 'react-router'

import {useHierarchyNavAccess} from '../../hooks'
import {HierarchyRouteSegment} from '../../routing'

const getAreaPlantPath = (basePath: string, areaId: string, plantId: string): string => {
  return `${basePath}/${HierarchyRouteSegment.AREA}/${areaId}/${HierarchyRouteSegment.PLANT}/${plantId}`
}

type PathParams = {
  plantId: string
}

type Props = {
  permission: PermissionType
  basePath: string
}

export const HierarchyPlantAccessRedirect = ({permission, basePath}: Props) => {
  const {plantId: requestedPlantId} = useParams<PathParams>()
  const {search, state} = useLocation()
  const {isInitialLoading, allowedAreas, getPlantsByAreaId} = useHierarchyNavAccess(permission)
  if (isInitialLoading) {
    return <Loader />
  }

  // Find the area that contains the requested plant
  const plantAreaId = allowedAreas.find((areaId) => {
    const plants = getPlantsByAreaId(areaId)
    return plants.some((plant) => plant.id === requestedPlantId)
  })

  if (requestedPlantId && plantAreaId) {
    return (
      <Navigate
        to={getAreaPlantPath(basePath, plantAreaId, requestedPlantId) + search}
        state={state}
      />
    )
  }

  // User does not have access to requested plant
  return <Navigate to={basePath} />
}
