import React from 'react'

type Props = {
  width: number
  height: number
  /** children components to render on top of the map (e.g. markers) absolute positioned in relation to the map container */
  children?: React.ReactNode
}

export const AreaMap = ({width, height, children}: Props) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        position: 'relative'
      }}
    >
      {children}
    </div>
  )
}
