import {Comments} from '@hconnect/common/comments'
import {getTranslationKey} from '@hconnect/common/utils'
import {formatDateLocale, timeFormatter} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix, usePlantTimezone} from '../../shared/hooks'
import {DataContentWrapper} from '../components'
import {
  useOptimizationSpecialistTimeRange,
  useGetShiftComments,
  useDeleteShiftComment,
  useUpdateShiftComment
} from '../hooks'
import {mapShiftEventCommentsToCommonComment} from '../mappers'

type Props = {
  plantId: string
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

export const CommentsContainer: React.FC<Props> = ({plantId, cardBoxSx, placeholderWrapperSx}) => {
  const {t, i18n} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {start, end} = useOptimizationSpecialistTimeRange()
  const timeZone = usePlantTimezone()

  const {data, isInitialLoading, isError, isRefetching, refetch} = useGetShiftComments(
    {
      plantId,
      startDate: start.toISOString(),
      endDate: end.toISOString()
    },
    {
      select: (comments) => mapShiftEventCommentsToCommonComment(comments, timeZone)
    }
  )
  const {mutate: deleteShiftComment, isLoading: isDeleting} = useDeleteShiftComment()
  const {mutate: updateShiftComment, isLoading: isUpdating} = useUpdateShiftComment()

  return (
    <CardBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...cardBoxSx
      }}
      data-test-id="comments-card"
    >
      <Box display="flex" flexDirection="row">
        <Typography variant="h3" mb={2}>
          {t(`${hrocPrefix}.comments.cardTitle`)}
        </Typography>
      </Box>

      <DataContentWrapper
        showLoader={isInitialLoading}
        isError={isError}
        isRefetching={isRefetching}
        data={data}
        retryFunction={() => void refetch()}
        progressSx={{color: 'primary.main'}}
        placeholderWrapperSx={placeholderWrapperSx}
      >
        <Box sx={{overflowY: 'auto'}}>
          <Comments
            translationPrefix={hrocPrefix}
            data={data}
            isLoading={isInitialLoading}
            showHeader={false}
            showAdd={false}
            deleteCommentProps={{
              onDelete: (id: string) => {
                deleteShiftComment({id, plantId})
              },
              deletingInProgress: isDeleting
            }}
            editCommentProps={{
              onEdit: (commentId: string, commentText: string) => {
                updateShiftComment({id: commentId, plantId, description: commentText})
              },
              savingChangesInProgress: isUpdating
            }}
            timeZone={timeZone}
          />
        </Box>
      </DataContentWrapper>
    </CardBox>
  )
}
