import {ContentBox} from '@hconnect/common/components/ContentBox'
import {Tag} from '@hconnect/common/components/Tag'
import {type AppName} from '@hconnect/common/hproduce'
import {RefererName} from '@hconnect/common/types'
import {Close as CloseIcon} from '@mui/icons-material'
import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {generatePath, useLocation, useNavigate} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks'
import {CompletedChecklistsTable, DataContentWrapper} from '../components'
import {useChecklistDoneForTemplate, useChecklistTemplates} from '../hooks/api'
import {PLANT_CHECKLIST} from '../routing'
import {type ChecklistDoneForTemplateDto, SortOrder} from '../types'

type Props = {
  plantId: string
  templateId: string
  appName: AppName
  page: number
  rowsPerPage: number
  sortOrder: SortOrder
  onSortChange: (columnKey: keyof ChecklistDoneForTemplateDto, sortOrder: SortOrder) => void
  onPaginationChange: (pageNumber: number, itemsPerPage: number) => void
  onClose: () => void
}

export const ChecklistTemplateContainer = ({
  plantId,
  templateId,
  appName,
  onClose,
  page,
  rowsPerPage,
  sortOrder,
  onSortChange,
  onPaginationChange
}: Props) => {
  const {data: template} = useChecklistTemplates(
    {plantId},
    {refetchOnMount: false, select: (data) => data.find((t) => t.id === templateId)}
  )
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {data, isInitialLoading, isFetching, isRefetching, isError, refetch} =
    useChecklistDoneForTemplate(
      {plantId, templateId, page, rowsPerPage, sortOrder},
      {enabled: !!template}
    )
  const {pathname, search} = useLocation()
  const navigate = useNavigate()

  const handleChecklistClick = (checklist: ChecklistDoneForTemplateDto) => {
    const targetPath = generatePath(PLANT_CHECKLIST, {
      plantId,
      checklistId: checklist.id
    })
    const searchParams = new URLSearchParams({
      refererAppName: appName,
      refererPath: pathname + search,
      refererName: RefererName.ChecklistTemplates
    })
    const path = `${targetPath}?${searchParams.toString()}`

    navigate(path)
  }

  if (!template) return null

  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={template.name}
      bodyWithPadding
      afterTitle={
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              p: 1.75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <CloseIcon
              onClick={onClose}
              color="primary"
              data-test-id="checklist-template-details-card-close"
            />
          </Typography>
        </Box>
      }
      data-test-id="checklist-template-details-card"
    >
      <Stack spacing={3}>
        <Tag labelKey="label.type" translationPrefix={hrocPrefix} data-test-id="template-type">
          {t(`${hrocPrefix}.checklist.templateType.${template.type}`)}
        </Tag>
        <Tag
          labelKey="label.description"
          translationPrefix={hrocPrefix}
          data-test-id="template-description"
        >
          {template.description}
        </Tag>
        <DataContentWrapper
          showLoader={isInitialLoading}
          isError={isError}
          data={data}
          retryFunction={() => void refetch()}
          isRefetching={isRefetching}
          progressSx={{color: 'primary.main'}}
        >
          <CompletedChecklistsTable
            data={data?.items ?? []}
            isLoading={isFetching}
            onRowClick={handleChecklistClick}
            sortOrder={sortOrder}
            onSortChange={onSortChange}
            paginationOptions={{
              page,
              rowsPerPage,
              totalDataLength: data?.totalCount ?? 0,
              onPaginationChange
            }}
          />
        </DataContentWrapper>
      </Stack>
    </ContentBox>
  )
}
