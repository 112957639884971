import {traverseWithKey} from '@hconnect/common/utils'
import {TimeZone} from '@hconnect/uikit'
import {timeRangeParamToDateRange} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {orderBy} from 'lodash'
import {getI18n} from 'react-i18next'

import {HierarchyNodeType} from '../../shared/types'
import {
  RcfaComplianceTableData,
  RcfaComplianceLocationNode,
  RcfaComplianceLocationNodeDto
} from '../types'
import {getRcfaComplianceQuickSelectionItems} from '../utils'

export const mapTimeRangeParam = (
  timezone: TimeZone,
  timeRange?: string
): {from?: string; to?: string} => {
  const selectionItems = getRcfaComplianceQuickSelectionItems(getI18n().t, timezone)
  const [from, to] = timeRangeParamToDateRange({timeRange, selectionItems})

  return {from: from?.toISOString(), to: to?.toISOString()}
}

export const mapToRcfaComplianceHierarchyTable = (
  stats: RcfaComplianceLocationNode[]
): RcfaComplianceTableData[] => {
  return stats.map(({id, type, name, rcfaCompliance, countryCode, subLocations}) => ({
    id,
    type,
    name,
    rcfaCompliance,
    countryCode: type !== HierarchyNodeType.Plant ? countryCode : undefined,
    subItems: mapToRcfaComplianceHierarchyTable(subLocations)
  }))
}

export const convertToLocationNodes = (
  data: RcfaComplianceLocationNodeDto
): RcfaComplianceLocationNode[] => data.children.map(convertToLocationNode)

export const convertToLocationNode = (
  dto: RcfaComplianceLocationNodeDto
): RcfaComplianceLocationNode => ({
  id: dto.id,
  name: dto.name,
  type: dto.type,
  rcfaCompliance: dto.rcfaCompliance,
  subLocations: dto.children.map(convertToLocationNode),
  countryCode: dto.countryCode
})

export const mapToRcfaCompliancePlantList = (
  statsList: RcfaComplianceLocationNode[]
): RcfaComplianceTableData[] => {
  const result: RcfaComplianceTableData[] = []

  traverseNodes(statsList, (stats: RcfaComplianceLocationNode) => {
    const {id, name, type, countryCode, rcfaCompliance} = stats
    if (type === HierarchyNodeType.Plant) {
      result.push({
        id,
        name,
        type,
        countryCode,
        rcfaCompliance,
        subItems: []
      })
    }
  })

  return orderBy(result, ['name'], ['asc'])
}

const traverseNodes = (
  nodes: RcfaComplianceLocationNode[],
  op: (node: RcfaComplianceLocationNode) => void
) => traverseWithKey('subLocations', nodes, op)
