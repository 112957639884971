import {FLEX_PAGE_HEADER_MX, FLEX_PAGE_HEADER_MY} from '@hconnect/common/components'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {PAGE_HEADER_ML} from '@hconnect/uikit/src/lib2/pageContainer/Page'
import {Box, SxProps, Theme} from '@mui/material'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'

import {findLocationEntry} from '../../shared/helpers'
import {useHierarchy} from '../../shared/hooks'
import {HierarchyNodeType} from '../../shared/types'
import {Breadcrumb, useHierarchyBreadcrumb} from '../hooks'

import {Breadcrumbs} from './Breadcrumbs'

type PathParameter = {
  plantId?: string
  areaId?: string
}

type Props = {
  sx?: SxProps<Theme>
}

export const LocationBreadcrumbs = ({sx}: Props) => {
  const {areaId, plantId} = useParams<PathParameter>()
  const {data: hierarchy} = useHierarchy({enabled: !!areaId})

  const options = useMemo(() => {
    const breadcrumbOptions: Breadcrumb[] = []
    if (areaId) {
      breadcrumbOptions.push({
        name: HierarchyNodeType.Area,
        id: 'areaId',
        label: hierarchy ? findLocationEntry(hierarchy, areaId)?.name || areaId : areaId
      })
    }
    if (plantId) {
      breadcrumbOptions.push({
        name: HierarchyNodeType.Plant,
        id: plantId,
        label: getPlantName(plantId)
      })
    }
    return breadcrumbOptions
  }, [areaId, hierarchy, plantId])

  const breadcrumbs = useHierarchyBreadcrumb(options)
  return (
    <Box
      component="span"
      display="flex"
      ml={FLEX_PAGE_HEADER_MX + PAGE_HEADER_ML}
      mt={FLEX_PAGE_HEADER_MY}
      sx={sx}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Box>
  )
}
