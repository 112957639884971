import React, {FC} from 'react'

import {PlantMapData} from '../../hooks'
import {Point, SubRegion} from '../../types'

import {AreaRegion} from './AreaRegion'
import {ResponsiveOverlay, ResponsiveOverlayContent} from './ResponsiveOverlay'

type AreaRegionOverlayProps = {
  width: number
  height: number
  translate: Point
  scale: number
  data: PlantMapData[]
  subRegions: SubRegion[]
}

export const AreaRegionOverlay: FC<AreaRegionOverlayProps> = ({
  width,
  height,
  scale,
  translate,
  data,
  subRegions
}) => {
  return (
    <ResponsiveOverlay width={width} height={height} absolute={true}>
      <ResponsiveOverlayContent scale={scale} translate={translate}>
        {subRegions.map((region) => (
          <AreaRegion
            key={region.id}
            {...region}
            data={data.filter(({parentId}) => parentId === region.id)}
          />
        ))}
      </ResponsiveOverlayContent>
    </ResponsiveOverlay>
  )
}
