import {appUrl} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {TASKS_SEGMENT} from '@hconnect/common/hproduce/consts'
import {DateRange} from '@hconnect/common/types'
import {
  generateQueryString,
  getTimeRangeOrQuickSelectSlotsString,
  QuickSelectSlots
} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, SummaryScores, SummaryScore} from '../components'
import {useOptimizationSpecialistTimeRange, useShiftTasksSummary} from '../hooks'
import {ShiftTasksSummary} from '../types'

type Props = {
  plantId: string
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

const getSummaryScoreItems = (
  prefix: string,
  t: TFunction,
  data?: ShiftTasksSummary
): SummaryScore[] => {
  return data
    ? [
        {
          label: t(`${prefix}.shiftTasksSummary.label.open`),
          value: data.open,
          valueVariant: 'neutral',
          showTooltip: data.open > 0,
          tooltipText: t(`${prefix}.shiftTasksSummary.tooltip.open`),
          dataTestId: 'shift-task-open'
        },
        {
          label: t(`${prefix}.shiftTasksSummary.label.done`),
          value: data.done,
          valueVariant: 'neutral',
          showTooltip: false,
          dataTestId: 'shift-task-done'
        },
        {
          label: t(`${prefix}.shiftTasksSummary.label.overdue`),
          value: data.overdue,
          valueVariant: data.overdue > 0 ? 'negative' : 'neutral',
          showTooltip: data.overdue > 0,
          tooltipText: t(`${prefix}.shiftTasksSummary.tooltip.overdue`),
          dataTestId: 'shift-task-overdue'
        }
      ]
    : []
}

const generateCockpitShiftTasksLink = (
  timeRange: DateRange | QuickSelectSlots,
  plantId: string
): string => {
  const query = {
    timeRange: getTimeRangeOrQuickSelectSlotsString(timeRange)
  }

  // TODO: extract all external app link generation to a separate file
  const baseUrl = appUrl(COCKPIT_HOST)
  const path = generatePath(`/:plantId/${TASKS_SEGMENT}`, {plantId})
  const endpoint = `${baseUrl}${path}`
  const linkPath = `${endpoint}?${generateQueryString(query)}`

  return linkPath
}

export const ShiftTasksContainer: React.FC<Props> = ({
  plantId,
  cardBoxSx,
  placeholderWrapperSx
}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {start, end, rawTimeRange} = useOptimizationSpecialistTimeRange()

  const {data, isInitialLoading, isError, isRefetching, refetch} = useShiftTasksSummary({
    plantId,
    startDate: start.toISOString(),
    endDate: end.toISOString()
  })

  const linkPath = generateCockpitShiftTasksLink(rawTimeRange, plantId)
  const items = getSummaryScoreItems(hrocPrefix, t, data)
  return (
    <CardLinkWrapper href={linkPath} data-test-id="shift-tasks-summary-card-link-to-shiftReport">
      <CardBox sx={cardBoxSx} data-test-id="shift-summary-card">
        <Box display="flex" flexDirection="row">
          <Typography variant="h3" mb={2}>
            {t(`${hrocPrefix}.shiftTasksSummary.cardTitle`)}
          </Typography>
        </Box>
        <DataContentWrapper
          showLoader={isInitialLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSx}
        >
          <SummaryScores items={items} />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
