import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers'
import {useAxios} from '../../../shared/hooks'
import {mapTimeRangeParam} from '../../mappers'
import {type RcfaComplianceHierarchyDto} from '../../types'

const QueryKey = 'rcfa-compliance'

type RcfaComplianceParams = {
  timezone: string
  timeRange: string
}

export const useRcfaCompliance = <TSelect = RcfaComplianceHierarchyDto[]>(
  {timezone, timeRange}: RcfaComplianceParams,
  options?: UseQueryOptions<RcfaComplianceHierarchyDto, AxiosError, TSelect>
) => {
  const navigate = useNavigate()
  const axiosInstance = useAxios()
  const {from, to} = mapTimeRangeParam(timezone, timeRange)

  return useQuery<RcfaComplianceHierarchyDto, AxiosError, TSelect>({
    queryKey: [QueryKey, from, to],
    queryFn: async () => {
      const response = await axiosInstance.get(`${ApiPath.RCFA_COMPLIANCE}/rcfa-compliance`, {
        params: {
          from,
          to
        }
      })

      return response.data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
