import {FilterDatePicker} from '@hconnect/common/components/dateTimeAndShift/FilterDatePicker'
import {DateRange} from '@hconnect/common/types'
import {QuickSelectSlots} from '@hconnect/common/utils'
import {Box} from '@mui/material'

import {useConfigData, useTranslationPrefix} from '../../shared/hooks'
import {useTimeRangeParam} from '../hooks'

export const QUICK_SELECT_SLOTS_OPTIMIZATION_SPECIALIST: QuickSelectSlots[] = [
  'currentShift',
  'pastShift',
  'today',
  'last24hours',
  'yesterday',
  'lastWeekend',
  'last3days',
  'last7days',
  'last30days'
]

export const OptimizationSpecialistFilterContainer = () => {
  const {hrocPrefix} = useTranslationPrefix()
  const {shifts, timezone, plantNow} = useConfigData()
  const {timeRange, setTimeRange} = useTimeRangeParam({
    defaultTimeRange: 'pastShift',
    key: 'timeRange',
    timezone: timezone
  })

  const onChange = (param: QuickSelectSlots | DateRange) => {
    setTimeRange(param)
  }

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
      <FilterDatePicker
        shifts={shifts}
        timezone={timezone}
        defaultTimeRange={'pastShift'}
        plantNow={plantNow()}
        onChange={onChange}
        value={timeRange}
        translationPrefix={hrocPrefix}
        quickSelectSlots={QUICK_SELECT_SLOTS_OPTIMIZATION_SPECIALIST}
      />
    </Box>
  )
}
