import {PermissionType} from '@hconnect/apiclient'
import {LocationSelect} from '@hconnect/common/components'
import {LocationSelectOption} from '@hconnect/common/hproduce/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {useTranslationPrefix} from '../../../shared/hooks'
import {DataContentWrapper} from '../../components'
import {useHierarchyNavAccess} from '../../hooks'

type AreatSelectProps = {
  path: string
  permission: PermissionType
}

export const AreaSelectByPermission = ({path, permission}: AreatSelectProps) => {
  const {hrocPrefix} = useTranslationPrefix()
  const {t} = useTranslation()
  const {isInitialLoading, isError, refetch, allowedAreas, getAreaById} =
    useHierarchyNavAccess(permission)

  const areasOptions: LocationSelectOption[] = allowedAreas.map((areaId) => {
    return {
      id: areaId,
      name: getAreaById(areaId).name,
      url: generatePath(path, {areaId})
    }
  })

  return (
    <PageContainer>
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={allowedAreas}
        isError={isError}
        retryFunction={() => void refetch()}
      >
        <LocationSelect
          options={areasOptions}
          selectALocationLabel={t(getTranslationKey('areaSelection.selectAnArea', hrocPrefix))}
          description={t(getTranslationKey('areaSelection.desc', hrocPrefix))}
          selectDropdownLabel={t(getTranslationKey('areaSelection.selectArea', hrocPrefix))}
        />
      </DataContentWrapper>
    </PageContainer>
  )
}
