import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

export type Breadcrumb = {
  name: string
  id: string
  label: string
}

export const useHierarchyBreadcrumb = (data: Breadcrumb[]) => {
  const location = useLocation()

  return useMemo(() => {
    const segments = location.pathname.split('/').filter(Boolean)
    let builtPath = ''
    const crumbs: {label: string; path: string}[] = []

    segments.forEach((segment, index) => {
      builtPath += `/${segment}`
      const breadcrumb = data.find(({name}) => name === segment)
      const nextSegment = segments[index + 1]
      if (breadcrumb && nextSegment) {
        builtPath += `/${nextSegment}`
        crumbs.push({
          label: breadcrumb.label || segment,
          path: builtPath
        })
      }
    })

    return crumbs
  }, [data, location.pathname])
}
