import {getTranslationKey} from '@hconnect/common/utils'
import {useNotification} from '@hconnect/uikit'
import {useQueryClient, useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../../shared/hooks'
import {QueryKeyName} from '../../../consts'

type UpdateCommentParameter = {
  id: string
  plantId: string
  description: string
}

export const useUpdateShiftComment = () => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const axiosInstance = useAxios()
  const client = useQueryClient()

  const {notify} = useNotification()

  return useMutation<void, AxiosError, UpdateCommentParameter>(
    async ({id, plantId, description}) => {
      const payload = {description}
      const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/shiftcomments/${id}`
      await axiosInstance.put(path, payload)
    },
    {
      onSuccess: () => {
        notify('success', t(getTranslationKey('comments.message.editSuccess', hrocPrefix)))
        void client.invalidateQueries([QueryKeyName.ShifthandoverComments])
      },
      onError: () => {
        notify('error', t(getTranslationKey('comments.message.failedToUpdateComment', hrocPrefix)))
      }
    }
  )
}
