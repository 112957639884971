import {TimeZone} from '@hconnect/uikit/src/common'
import {SelectionItem} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/types'
import moment from 'moment-timezone'

import {type RcfaComplianceQuickSelectionId, RcfaComplianceQuickSelectionIds} from '../types'

const ALL_OPTION_START_DATE = '2024-01-01'

export const getRcfaComplianceQuickSelectionItems = (
  translationFn: (key: string) => string,
  timezone: TimeZone,
  now = new Date()
): SelectionItem<RcfaComplianceQuickSelectionId>[] => {
  const startOfToday = moment.utc(now).tz(timezone).startOf('day')
  const endOfToday = startOfToday.clone().endOf('day')
  const quickSelectItems: SelectionItem<RcfaComplianceQuickSelectionId>[] = [
    {
      id: RcfaComplianceQuickSelectionIds.ALL,
      title: translationFn('quickSelection.all'),
      dates: [
        moment.utc(ALL_OPTION_START_DATE).tz(timezone).startOf('day').toDate(),
        endOfToday.toDate()
      ]
    },
    {
      id: RcfaComplianceQuickSelectionIds.YEAR_TO_DATE_YTD,
      title: translationFn('quickSelection.yearToDateYTD'),
      dates: [startOfToday.clone().startOf('year').toDate(), endOfToday.toDate()]
    },
    {
      id: RcfaComplianceQuickSelectionIds.LAST_YEAR,
      title: translationFn('quickSelection.lastYear'),
      dates: [
        startOfToday.clone().subtract(1, 'year').startOf('year').toDate(),
        startOfToday.clone().subtract(1, 'year').endOf('year').toDate()
      ]
    }
  ]

  return quickSelectItems
}
