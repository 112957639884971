import {PLANT_CHECKLIST, HPRODUCE_HROC} from '@hconnect/common/hproduce/config'

import {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD
} from '../../shared/routes/routes'

export {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD
}

export const PLANT_CHECKLISTS_TEMPLATES = `${CHECKLISTS}/plant/:plantId/templates`

export const RCFA_COMPLIANCE = `${HPRODUCE_HROC}/rcfa-compliance`

export enum HierarchyRouteSegment {
  AREA = 'area',
  PLANT = 'plant'
}
