import {getMaintainRcfaStoppageListUrl, getTranslationKey} from '@hconnect/common/utils'
import {CollapsableRow, expandableTableHeaderCellSx} from '@hconnect/uikit/src/lib2'
import {Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Box} from '@mui/material'
import {isEqual} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {HierarchyNodeType} from '../../shared/types'
import {RcfaComplianceTableData} from '../types'

import {
  renderRcfaComplianceTableCell,
  RCFA_COMPLIANCE_TABLE_KEYS,
  HeaderWithTooltip
} from './RcfaComplianceTableRow'

type Props = {
  data: RcfaComplianceTableData[]
  getIsExpanded: (itemId: string) => boolean
  toggleExpandCollapse: (itemId: string) => void
  translationPrefix?: string
}

const collapseToggleTestIdGenerator = (itemId: string | number) => `table-row-toggle-${itemId}`

const RcfaComplianceHierarchyTableComponent = ({
  data,
  getIsExpanded,
  toggleExpandCollapse,
  translationPrefix
}: Props) => {
  const {t} = useTranslation()

  const onItemToggled = useCallback(
    (item: RcfaComplianceTableData) => toggleExpandCollapse(item.id),
    [toggleExpandCollapse]
  )
  const isItemExpanded = useCallback(
    (it: RcfaComplianceTableData) => getIsExpanded(it.id),
    [getIsExpanded]
  )
  const getItemProps = useCallback(
    (item: RcfaComplianceTableData) =>
      item.type === HierarchyNodeType.Plant
        ? {
            onClick: ({id: plantId}: RcfaComplianceTableData) => {
              const url = getMaintainRcfaStoppageListUrl(plantId)
              // navigate to different domain
              window.location.assign(url)
            }
          }
        : {},
    []
  )

  return (
    <TableContainer data-test-id="rcfa-compliance-hierarchy-table">
      <Table sx={{width: '100%'}}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box sx={expandableTableHeaderCellSx(true)}>
                {t(getTranslationKey('rcfaCompliance.label.location', translationPrefix))}
              </Box>
            </TableCell>
            <TableCell>
              <HeaderWithTooltip
                headerText={`${t(
                  getTranslationKey('rcfaCompliance.label.rcfas', translationPrefix),
                  'RCFAs'
                )} ${t(
                  getTranslationKey('rcfaCompliance.label.completed', translationPrefix),
                  'Completed'
                )}/${t(
                  getTranslationKey('rcfaCompliance.label.mandadotory', translationPrefix),
                  'Mandatory'
                )}`}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.rcfaScore', translationPrefix)
                )}
              />
            </TableCell>
            <TableCell />
            <TableCell>
              <HeaderWithTooltip
                headerText={t(
                  getTranslationKey('rcfaCompliance.label.unresolvedTasks', translationPrefix)
                )}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.unresolvedTasks', translationPrefix)
                )}
                sx={{justifyContent: 'flex-end'}}
              />
            </TableCell>
            <TableCell>
              <HeaderWithTooltip
                headerText={t(
                  getTranslationKey('rcfaCompliance.label.overdueTasks', translationPrefix)
                )}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.overdueTasks', translationPrefix)
                )}
                sx={{justifyContent: 'flex-end'}}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <CollapsableRow
              key={item.id}
              item={item}
              columnKeys={RCFA_COMPLIANCE_TABLE_KEYS}
              renderCell={renderRcfaComplianceTableCell}
              collapseToggleTestIdGenerator={collapseToggleTestIdGenerator}
              isItemExpanded={isItemExpanded}
              onExpandToggleClicked={onItemToggled}
              itemPropsFn={getItemProps}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const RcfaComplianceHierarchyTable = React.memo(
  RcfaComplianceHierarchyTableComponent,
  isEqual
)
