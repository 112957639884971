import {customThemeConstants} from '@hconnect/uikit/src/lib2'

type Props = {
  path: string
}

export const CountryBorders = ({path}: Props) => {
  return (
    <path
      d={path}
      fill={customThemeConstants().palette.mapAreaFill}
      stroke={customThemeConstants().palette.mapAreaStroke}
      strokeWidth={2}
    />
  )
}
