export type Point = {
  x: number
  y: number
}
export type Bounds = {
  topLeft: Point
  bottomRight: Point
}
export type BordersPath = {id: string; path: string}
export type SubRegion = {
  name: string
  id: string
  defsConfigs: {clipPathId: string; path: string}[]
  bounds: Bounds
}
export type MapAreaConfig = {
  priorityBounds: Bounds
  bordersPaths: BordersPath[]
  subRegions: SubRegion[]
  plantLocationMap: Record<string, Point>
}
