import {RunningTimeType} from '@hconnect/common/types'
import {Stack, Typography} from '@mui/material'
import {Variant} from '@mui/material/styles/createTypography'
import {TFunction} from 'i18next'
import {isBoolean, isEmpty} from 'lodash'
import {memo, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks'
import {PlantStatusEquipment, PxTrendMetaData} from '../../../shared/types'

import {IconSize, PerformanceMapMarkerType} from './maps.types'
import {PerformanceMapIcon} from './PerformanceMapIndicator'

enum PerformanceMapTooltipType {
  Equipment = 'equipment',
  NoPxTrend = 'noPxTrend',
  GrindingUnitOnly = 'grindingUnitOnly',
  NoData = 'noData'
}

const tooltipMarkerTypeMap: Record<PerformanceMapMarkerType, PerformanceMapTooltipType> = {
  [PerformanceMapMarkerType.Success]: PerformanceMapTooltipType.Equipment,
  [PerformanceMapMarkerType.Error]: PerformanceMapTooltipType.Equipment,
  [PerformanceMapMarkerType.NoPxTrend]: PerformanceMapTooltipType.NoPxTrend,
  [PerformanceMapMarkerType.GrindingUnitOnly]: PerformanceMapTooltipType.GrindingUnitOnly,
  [PerformanceMapMarkerType.NoData]: PerformanceMapTooltipType.NoData
}

export const getMarkerType = (
  equipments: PlantStatusEquipment[],
  pxTrendMetaData?: PxTrendMetaData
) => {
  const {isGrindingUnit, pxTrendAvailable} = pxTrendMetaData || {}

  if (isEmpty(equipments)) {
    return isEmpty(pxTrendMetaData) || (isBoolean(pxTrendAvailable) && !pxTrendAvailable)
      ? PerformanceMapMarkerType.NoPxTrend
      : isGrindingUnit
        ? PerformanceMapMarkerType.GrindingUnitOnly
        : PerformanceMapMarkerType.NoData
  }
  return equipments.every((equipment) => equipment.status === RunningTimeType.RUNNING)
    ? PerformanceMapMarkerType.Success
    : PerformanceMapMarkerType.Error
}
const TooltipItem = ({type, label}: {type: PerformanceMapMarkerType; label: string}) => (
  <Typography variant="subtitle1" display="inline-flex" alignItems="center" gap={0.75}>
    <PerformanceMapIcon type={type} size={IconSize.Small} active={false} />
    {label}
  </Typography>
)

const tooltipContent: Record<
  PerformanceMapTooltipType,
  (t: TFunction, tPrefix: string, equipments?: PlantStatusEquipment[]) => ReactNode
> = {
  [PerformanceMapTooltipType.Equipment]: (t, tPrefix, equipments = []) =>
    equipments.map((eq) => {
      const isRunning = eq.status === RunningTimeType.RUNNING
      return (
        <Stack key={eq.id}>
          <Typography variant="body2">{eq.name}</Typography>
          <TooltipItem
            type={isRunning ? PerformanceMapMarkerType.Success : PerformanceMapMarkerType.Error}
            label={t(`${tPrefix}.label.${isRunning ? 'running' : 'stopped'}`)}
          />
        </Stack>
      )
    }),
  [PerformanceMapMarkerType.NoPxTrend]: (t, tPrefix) => (
    <TooltipItem
      type={PerformanceMapMarkerType.NoPxTrend}
      label={t(`${tPrefix}.label.noPxTrend`)}
    />
  ),
  [PerformanceMapMarkerType.GrindingUnitOnly]: (t, tPrefix) => (
    <TooltipItem
      type={PerformanceMapMarkerType.GrindingUnitOnly}
      label={t(`${tPrefix}.label.grindingUnitOnly`)}
    />
  ),
  [PerformanceMapMarkerType.NoData]: (t, tPrefix) => (
    <TooltipItem type={PerformanceMapMarkerType.NoData} label={t(`${tPrefix}.label.noData`)} />
  )
}

type PlantTooltipContentProps = {
  plantName: string
  headerVariant?: Variant
  equipments: PlantStatusEquipment[]
  type: PerformanceMapMarkerType
}

export const PlantTooltipContent = memo<PlantTooltipContentProps>(
  ({plantName, equipments, type, headerVariant = 'h5'}) => {
    const {t} = useTranslation()
    const {hrocPrefix} = useTranslationPrefix()

    return (
      <Stack gap={1}>
        <Typography variant={headerVariant}>{plantName}</Typography>
        {tooltipContent[tooltipMarkerTypeMap[type]](t, hrocPrefix, equipments)}
      </Stack>
    )
  }
)
