import {useCallback, useState} from 'react'

export const useExpandedState = (allIds: string[]) => {
  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set())

  const allExpanded = expandedIds.size === allIds.length
  const toggleExpandCollapse = useCallback((id: string) => {
    setExpandedIds((expanded) => {
      const newExpanded = new Set(expanded)
      newExpanded.has(id) ? newExpanded.delete(id) : newExpanded.add(id)
      return newExpanded
    })
  }, [])
  const toggleExpandCollapseAll = useCallback(() => {
    allExpanded ? setExpandedIds(new Set()) : setExpandedIds(new Set(allIds))
  }, [allIds, allExpanded])
  const getIsExpanded = useCallback((id: string) => expandedIds.has(id), [expandedIds])

  return {allExpanded, getIsExpanded, toggleExpandCollapse, toggleExpandCollapseAll}
}
