import {DateRange} from '@hconnect/common/types'
import {getQuickSelectFilter, QuickSelectSlots} from '@hconnect/common/utils'
import {isString} from 'lodash'
import {Moment} from 'moment'

import {useConfigData} from '../../shared/hooks'

import {useTimeRangeParam} from './useTimeRangeParam'

type OptimizationSpecialistTimeRangeResult = {
  start: Moment
  end: Moment
  rawTimeRange: DateRange | QuickSelectSlots
}

export const useOptimizationSpecialistTimeRange = (): OptimizationSpecialistTimeRangeResult => {
  const {shifts, timezone, plantNow} = useConfigData()
  const {timeRange} = useTimeRangeParam({
    defaultTimeRange: 'pastShift',
    key: 'timeRange',
    timezone: timezone
  })

  const isQuickSelectSlots = isString(timeRange)
  if (isQuickSelectSlots) {
    const timeRangeSelectSlot = timeRange as QuickSelectSlots
    const [startDate, endDate] = getQuickSelectFilter(timeRangeSelectSlot, shifts, plantNow())

    return {
      start: startDate,
      end: endDate,
      rawTimeRange: timeRangeSelectSlot
    }
  }

  const {startDate, endDate}: DateRange = timeRange
  return {
    start: startDate,
    end: endDate,
    rawTimeRange: timeRange
  }
}
