import {Tooltip} from '@mui/material'
import {isEqual} from 'lodash'
import {memo} from 'react'

import {PlantStatusEquipment, PxTrendMetaData} from '../../../shared/types'
import {Point} from '../../types'

import {PerformanceMapIcon} from './PerformanceMapIndicator'
import {getMarkerType, PlantTooltipContent} from './PlantTooltipContent'

type PerformanceMapMarkerProps = {
  equipments: PlantStatusEquipment[]
  plantName: string
  coordinate?: Point
  pxTrendMetaData?: PxTrendMetaData
  onClick?: () => void
  dataTestId?: string
}

export const PerformanceMapMarker = memo<PerformanceMapMarkerProps>(
  ({equipments, pxTrendMetaData, coordinate, plantName, onClick, dataTestId}) => {
    if (!coordinate) return null

    const type = getMarkerType(equipments, pxTrendMetaData)

    return (
      <Tooltip
        title={<PlantTooltipContent plantName={plantName} equipments={equipments} type={type} />}
        placement="bottom-start"
      >
        <span
          style={{
            position: 'absolute',
            top: coordinate.y,
            left: coordinate.x,
            transform: 'translate(-50%, -50%)'
          }}
          data-test-id={dataTestId}
        >
          <PerformanceMapIcon type={type} onClick={onClick} />
        </span>
      </Tooltip>
    )
  },
  isEqual
)
