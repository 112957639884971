import {CardBox} from '@hconnect/uikit/src/lib2'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone, useTranslationPrefix} from '../../shared/hooks'
import {
  CardLinkWrapper,
  DataContentWrapper,
  PlantStatusCardHeader,
  PlantStatusDailyKpis
} from '../components'
import {useDailyPerformanceKpis, useMonthlyAggregatedKpis} from '../hooks/api'
import {
  formatKpiValue,
  getHasDenodoDataForDate,
  getReliabilityEngineerPerformancePagePath
} from '../utils'

type Props = {
  plantId: string
}

const AGGREGATION_MONTHS_COUNT = 1

export const ReliabilityPlantStatusDailyKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const to = moment.utc().tz(timezone).startOf('month')
  const from = to.clone().subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {data: monthlyAggregatedKpis} = useMonthlyAggregatedKpis({plantId, from, to})

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = startOfYesterday.clone().endOf('day')
  const {
    data: yesterdayKpis,
    isInitialLoading: isYesterdayKpisInitialLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  const lastMonth = moment.tz(timezone).subtract(1, 'month')
  const hasLastMonthDenodoData = getHasDenodoDataForDate(monthlyAggregatedKpis, lastMonth)
  const linkPath = getReliabilityEngineerPerformancePagePath(
    plantId,
    hasLastMonthDenodoData ? 'lastMonth' : 'yesterday'
  )

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(yesterdayKpis?.clinkerProduction?.value, language),
        unit: yesterdayKpis?.clinkerProduction?.unit,
        kpiBreachSeverity: yesterdayKpis?.clinkerProduction?.kpiBreachSeverity
      },
      {
        key: 'kiln-operating-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnOperatingCoefficient`)
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(yesterdayKpis?.cementProduction?.value, language),
        unit: yesterdayKpis?.cementProduction?.unit,
        kpiBreachSeverity: yesterdayKpis?.cementProduction?.kpiBreachSeverity
      },
      {
        key: 'alternative-fuel-rate',
        label: t(`${hrocPrefix}.plantStatus.label.alternativeFuelRate`),
        value: formatKpiValue(yesterdayKpis?.alternativeFuelRate?.value, language),
        unit: yesterdayKpis?.alternativeFuelRate?.unit,
        kpiBreachSeverity: yesterdayKpis?.alternativeFuelRate?.kpiBreachSeverity
      },
      {
        key: 'kiln-mean-time-between-failure',
        label: t(`${hrocPrefix}.plantStatus.label.kilnMeanTimeBetweenFailure`),
        value: formatKpiValue(yesterdayKpis?.kilnMTBF?.value, language),
        unit: yesterdayKpis?.kilnMTBF?.unit,
        kpiBreachSeverity: yesterdayKpis?.kilnMTBF?.kpiBreachSeverity
      },
      {
        key: 'kiln-reliability-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnReliabilityCoefficient`)
      }
    ],
    [yesterdayKpis, t, hrocPrefix, language]
  )

  return (
    <CardLinkWrapper
      href={linkPath}
      data-test-id="plant-status-kpis-card-link-to-performance-plant-status"
    >
      <CardBox data-test-id="plant-status-kpis-card">
        <PlantStatusCardHeader />
        <DataContentWrapper
          showLoader={isYesterdayKpisInitialLoading}
          isError={isYesterdayKpisError}
          isRefetching={isYesterdayKpisRefetching}
          data={yesterdayKpis}
          retryFunction={() => void refetchYesterdayKpis()}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusDailyKpis kpiItems={kpiItems} />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
