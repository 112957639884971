import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {getSystemTimezone, useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, PlantStatusDailyKpis} from '../components'
import {useEquipmentPerformancesMonthly} from '../hooks/api'
import {HierarchyRouteSegment} from '../routing'
import {MonthlyKpiSummaryDto} from '../types'
import {formatKpiValue} from '../utils'

type Props = {
  plantId: string
  kpiGridItemXs?: number
  cardBoxSx?: SxProps<Theme>
}

const AGGREGATION_MONTHS_COUNT = 1

const lastMonthKpisSelector = (data: MonthlyKpiSummaryDto[]) => data.at(0)

export const ProcessLastMonthKpisContainer: React.FC<Props> = ({
  plantId,
  cardBoxSx,
  kpiGridItemXs = 4
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = getSystemTimezone()
  const to = moment.tz(timezone).startOf('month')
  const from = to.clone().subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {
    data: lastMonthKpis,
    isInitialLoading,
    isError,
    isRefetching,
    refetch
  } = useEquipmentPerformancesMonthly({plantId, from, to}, {select: lastMonthKpisSelector})

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(lastMonthKpis?.clinkerProduction?.value?.toString(), language),
        unit: lastMonthKpis?.clinkerProduction?.unit
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(lastMonthKpis?.cementProduction?.value?.toString(), language),
        unit: lastMonthKpis?.cementProduction?.unit
      },
      {
        key: 'kiln-heat-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.kilnHeatConsumption`),
        value: formatKpiValue(lastMonthKpis?.kilnHeatConsumption?.value?.toString(), language),
        unit: lastMonthKpis?.kilnHeatConsumption?.unit
      },
      {
        key: 'raw-mill-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.rawMillsSpPowerConsumption`),
        value: formatKpiValue(lastMonthKpis?.rawMillsPowerConsumption?.value?.toString(), language),
        unit: lastMonthKpis?.rawMillsPowerConsumption?.unit
      },
      {
        key: 'kiln-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.kilnPowerConsumption`),
        value: formatKpiValue(lastMonthKpis?.kilnPowerConsumption?.value?.toString(), language),
        unit: lastMonthKpis?.kilnPowerConsumption?.unit
      },
      {
        key: 'cement-mills-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.cementMillsPowerConsumption`),
        value: formatKpiValue(
          lastMonthKpis?.cementMillsPowerConsumption?.value?.toString(),
          language
        ),
        unit: lastMonthKpis?.cementMillsPowerConsumption?.unit
      },
      {
        key: 'raw-mills-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.rawMillsProductionRate`),
        value: formatKpiValue(lastMonthKpis?.rawMillsProductionRate?.value?.toString(), language),
        unit: lastMonthKpis?.rawMillsProductionRate?.unit
      },
      {
        key: 'clinker-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProductionRate`),
        value: formatKpiValue(lastMonthKpis?.clinkerProductionRate?.value?.toString(), language),
        unit: lastMonthKpis?.clinkerProductionRate?.unit
      },
      {
        key: 'cement-mills-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.cementMillsProductionRate`),
        value: formatKpiValue(
          lastMonthKpis?.cementMillsProductionRate?.value?.toString(),
          language
        ),
        unit: lastMonthKpis?.cementMillsProductionRate?.unit
      }
    ],
    [lastMonthKpis, t, hrocPrefix, language]
  )

  return (
    <CardLinkWrapper<typeof Link> component={Link} to={`${HierarchyRouteSegment.PLANT}/${plantId}`}>
      <CardBox sx={cardBoxSx} data-test-id={`plant-status-kpis-card-${plantId}`}>
        <Typography variant="h3" mb={2}>
          {getPlantName(plantId)}
        </Typography>
        <DataContentWrapper
          showLoader={isInitialLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={lastMonthKpis}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusDailyKpis kpiItems={kpiItems} gridItemXs={kpiGridItemXs} kpiSize="S" />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
