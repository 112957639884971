import {KpiThresholdIcon} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiScore} from '@hconnect/uikit/src/lib2'
import {Box, Grid, Stack} from '@mui/material'

import {NO_VALUE_STRING} from '../consts'
import type {KpiItem} from '../types'

import {KpiTrendChart, KpiTrendChartSkeleton} from './KpiTrendChart'

const emptyKpiTrendData = {labels: [], data: []}

type Props = {
  kpiItems: KpiItem[]
  showKpiValueLoader?: boolean
  areMonthlyKpiTrendsLoading: boolean
  gridColumns?: number
  gridItemXs: number
}

export const PlantStatusKpis: React.FC<Props> = ({
  kpiItems,
  showKpiValueLoader,
  areMonthlyKpiTrendsLoading,
  gridItemXs,
  gridColumns = 12
}) => {
  return (
    <Grid container columns={gridColumns} spacing={2} sx={{maxWidth: '100%', maxHeight: '100%'}}>
      {kpiItems.map((kpiItem) => (
        <Grid item xs={gridItemXs} key={kpiItem.key}>
          <Stack width="100%" height="100%" justifyContent="space-between">
            <KpiScore
              value={kpiItem.value ?? NO_VALUE_STRING}
              variant={kpiItem.value ? 'neutral' : 'no-value'}
              label={kpiItem.label}
              unit={kpiItem.unit}
              valueIcon={
                kpiItem.kpiBreachSeverity ? (
                  <KpiThresholdIcon status={kpiItem.kpiBreachSeverity} variant="filled" />
                ) : undefined
              }
              size="L"
              showValueLoader={showKpiValueLoader}
              data-test-id={`kpi-score-${kpiItem.key}`}
            />
            <Box sx={{width: '100%', maxHeight: '100%', mt: 1}}>
              {areMonthlyKpiTrendsLoading ? (
                <KpiTrendChartSkeleton />
              ) : (
                <KpiTrendChart
                  data={kpiItem.monthlyTrendData ?? emptyKpiTrendData}
                  unit={kpiItem.monthlyTrendDataUnit}
                />
              )}
            </Box>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
