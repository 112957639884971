import React, {FC, ReactNode} from 'react'

import {Point} from '../../types'

type ResponsiveOverlayProps = {
  width: number
  height: number
  children: ReactNode
  absolute?: boolean
}

type ResponsiveOverlayContentProps = {
  translate: Point
  scale: number
  children: ReactNode
}

export const ResponsiveOverlay: FC<ResponsiveOverlayProps> = ({
  width,
  height,
  children,
  absolute
}) => {
  return (
    <svg width={width} height={height} style={absolute ? {position: 'absolute'} : undefined}>
      {children}
    </svg>
  )
}

export const ResponsiveOverlayContent: FC<ResponsiveOverlayContentProps> = ({
  children,
  translate,
  scale
}) => {
  return <g transform={`translate(${translate.x}, ${translate.y}) scale(${scale})`}>{children}</g>
}
