import {DateRange} from '@hconnect/common/types'
import {
  generateTimeRangeFromUrlParam,
  getTimeRangeOrQuickSelectSlotsString,
  QuickSelectSlots
} from '@hconnect/common/utils'
import {useSearchParams} from 'react-router-dom'

import {getUrlParam, setOrDeleteStringParam} from '../../shared/helpers'

type Props = {
  key: string
  timezone: string
  defaultTimeRange: string
}

export const useTimeRangeParam = ({defaultTimeRange, key, timezone}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const timeRangeParam = getUrlParam(searchParams, key)

  const setTimeRangeParam = (value: QuickSelectSlots | DateRange) => {
    const timeRangeString = getTimeRangeOrQuickSelectSlotsString(value)
    const newParams = new URLSearchParams(searchParams)

    setOrDeleteStringParam(newParams, key, timeRangeString)
    setSearchParams(newParams)
  }
  return {
    timeRange: generateTimeRangeFromUrlParam(timeRangeParam ?? defaultTimeRange, timezone),
    setTimeRange: setTimeRangeParam
  }
}
