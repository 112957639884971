import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'

import {HierarchyNodeMetadata, HierarchyNodeType} from '../../shared/types'

type AreaMap = Record<string, HierarchyNodeMetadata[]>
type AreaMetadata = Record<string, {id: string; name: string}>

export function filterHierarchyByPlantIds(
  node: HierarchyNodeMetadata,
  plantIds: string[]
): HierarchyNodeMetadata | null {
  if (node.type === HierarchyNodeType.Plant) {
    return plantIds.includes(node.id) ? node : null
  }
  const filteredChildren = node.children
    .map((child) => filterHierarchyByPlantIds(child, plantIds))
    .filter((child) => child !== null) as HierarchyNodeMetadata[]

  return filteredChildren.length ? {...node, children: filteredChildren} : null
}

function ensureArea(map: AreaMap, areaId: string) {
  if (!map[areaId]) {
    map[areaId] = []
  }
}

export function collectPlantsByArea(
  node: HierarchyNodeMetadata,
  currentArea: string | null = null
): AreaMap {
  const areaToPlants: AreaMap = {}
  if (node.type === HierarchyNodeType.Area) {
    currentArea = node.id
    ensureArea(areaToPlants, currentArea)
  }
  if (node.type === HierarchyNodeType.Plant && currentArea) {
    ensureArea(areaToPlants, currentArea)
    areaToPlants[currentArea].push(node)
  }
  node.children.forEach((child) => {
    const childResult = collectPlantsByArea(child, currentArea)
    for (const [areaId, plants] of Object.entries(childResult)) {
      ensureArea(areaToPlants, areaId)
      areaToPlants[areaId].push(...plants)
    }
  })

  return areaToPlants
}

export function collectAreaMetadata(node: HierarchyNodeMetadata): AreaMetadata {
  let result: AreaMetadata = {}
  if (node.type === HierarchyNodeType.Area) {
    result[node.id] = {id: node.id, name: node.name}
  }
  node.children.forEach((child) => {
    const childAreas = collectAreaMetadata(child)
    result = {...result, ...childAreas}
  })

  return result
}

export const getAllowedLocations = (
  hierarchy: HierarchyNodeMetadata | null | undefined,
  allowedPlantsIds: string[]
) => {
  const filteredRoot = hierarchy ? filterHierarchyByPlantIds(hierarchy, allowedPlantsIds) : null
  const areas = filteredRoot ? collectAreaMetadata(filteredRoot) : {}
  const plantsByArea = filteredRoot ? collectPlantsByArea(filteredRoot) : {}

  return {areas, plantsByArea}
}

export const isValidAreaId = (areaId?: string): areaId is string => {
  return !!areaId
}

export const plantIdsByPlantNameCompareFn = (a: string, b: string) =>
  getPlantName(a) > getPlantName(b) ? 1 : -1
