import {Permission, PermissionType} from '@hconnect/apiclient'
import {Route, Outlet} from 'react-router-dom'

import {checkFeatureFlag} from '../shared/helpers'
import {
  ConfigProviderWrapper,
  PermissionsContextProvider,
  TranslationPrefixProvider
} from '../shared/hooks'
import {DefaultShell} from '../shared/layouts'
import {Context} from '../shared/types'

import {UserContextProvider} from './hooks'
import {
  AreaPlantSelectByPermission,
  AreaSelectByPermission,
  AreaReliabilityEngineerDashboard,
  ChecklistsTemplatesPage,
  OptimizationSpecialistDashboard,
  PlantChecklistPage,
  PlantSelectByPermission,
  ProcessEngineerDashboard,
  RcfaCompliancePage,
  ReliabilityEngineerDashboard,
  HierarchyLocationAccessRedirect,
  AreaProcessEngineerDashboard,
  AreaOptimizationSpecialistDashboard,
  HierarchyPlantAccessRedirect
} from './pages'
import {PlantPermissionGuardedRoute} from './routing'
import {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD,
  RCFA_COMPLIANCE,
  HierarchyRouteSegment
} from './routing/routes'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission,
  hasViewProcessEngineerDashboardPermission,
  hasViewOptimizationSpecialistDashboardPermission,
  hasRcfaCompliancePagePermission
} from './utils'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <TranslationPrefixProvider>
    <UserContextProvider initialState={context.user}>
      <PermissionsContextProvider initialState={permissions}>
        <DefaultShell context={context}>{children}</DefaultShell>
      </PermissionsContextProvider>
    </UserContextProvider>
  </TranslationPrefixProvider>
)

export const getHrocRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const isOptimizationSpecialistDashboardEnabled = checkFeatureFlag(
    'optimizationSpecialistDashboard'
  )
  const isRcfaComplianceDashboardEnabled = checkFeatureFlag('rcfaCompliance')

  return (
    <>
      {areChecklistsEnabled && hasChecklistsPermission(permissions) && (
        <>
          <Route
            key="checklists-plant-select"
            path={CHECKLISTS}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_CHECKLISTS_TEMPLATES}
                  permission="VIEW_CHECKLISTS"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLISTS_TEMPLATES}
            key="checklists-templates"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_CHECKLISTS"
                  plantSelectPath={CHECKLISTS}
                >
                  <ConfigProviderWrapper>
                    <ChecklistsTemplatesPage />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLIST}
            key="plant-checklist"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantChecklistPage />
              </Wrapper>
            }
          />
        </>
      )}
      {hasViewProcessEngineerDashboardPermission(permissions)
        ? getProcessEngineerDashboardRoutes({context, permissions})
        : null}

      {hasViewReliabilityEngineerDashboardPermission(permissions)
        ? getReliabilityEngineerDashboardRoutes({context, permissions})
        : null}

      {isOptimizationSpecialistDashboardEnabled &&
      hasViewOptimizationSpecialistDashboardPermission(permissions)
        ? getOptimizationSpecialistDashboardRoutes({context, permissions})
        : null}

      {isRcfaComplianceDashboardEnabled && hasRcfaCompliancePagePermission(permissions) && (
        <Route
          key="rcfa-complience-dashboard"
          path={RCFA_COMPLIANCE}
          element={
            <Wrapper context={context} permissions={permissions}>
              <RcfaCompliancePage />
            </Wrapper>
          }
        />
      )}
    </>
  )
}

const getProcessEngineerDashboardRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  if (checkFeatureFlag('hierarchyProcessEngineerDashboard')) {
    return getLocationBasedNavigationRoutes({
      key: 'process-engineer-dashboard-routes',
      context,
      permissions,
      basePath: PROCESS_ENGINEER_DASHBOARD,
      permission: 'VIEW_PROCESS_ENGINEER_DASHBOARD',
      areaRouteElement: <AreaProcessEngineerDashboard />,
      plantRouteElement: (
        <ConfigProviderWrapper>
          <ProcessEngineerDashboard />
        </ConfigProviderWrapper>
      )
    })
  }

  return (
    <>
      <Route
        key="process-engineer-dashboard-plant-select"
        path={PROCESS_ENGINEER_DASHBOARD}
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantSelectByPermission
              path={PLANT_PROCESS_ENGINEER_DASHBOARD}
              permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
            />
          </Wrapper>
        }
      />
      <Route
        path={PLANT_PROCESS_ENGINEER_DASHBOARD}
        key="process-engineer-dashboard"
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantPermissionGuardedRoute
              permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
              plantSelectPath={PROCESS_ENGINEER_DASHBOARD}
            >
              <ConfigProviderWrapper>
                <ProcessEngineerDashboard />
              </ConfigProviderWrapper>
            </PlantPermissionGuardedRoute>
          </Wrapper>
        }
      />
    </>
  )
}

const getReliabilityEngineerDashboardRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  if (checkFeatureFlag('hierarchyReliabilityEngineerDashboard')) {
    return getLocationBasedNavigationRoutes({
      key: 'reliability-engineer-dashboard-routes',
      context,
      permissions,
      basePath: RELIABILITY_ENGINEER_DASHBOARD,
      permission: 'VIEW_RELIABILITY_ENGINEER_DASHBOARD',
      areaRouteElement: <AreaReliabilityEngineerDashboard />,
      plantRouteElement: (
        <ConfigProviderWrapper>
          <ReliabilityEngineerDashboard />
        </ConfigProviderWrapper>
      )
    })
  }

  return (
    <>
      <Route
        key="reliability-engineer-dashboard-plant-select"
        path={RELIABILITY_ENGINEER_DASHBOARD}
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantSelectByPermission
              path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
              permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
            />
          </Wrapper>
        }
      />
      <Route
        path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
        key="reliability-engineer-dashboard"
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantPermissionGuardedRoute
              permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
              plantSelectPath={RELIABILITY_ENGINEER_DASHBOARD}
            >
              <ConfigProviderWrapper>
                <ReliabilityEngineerDashboard />
              </ConfigProviderWrapper>
            </PlantPermissionGuardedRoute>
          </Wrapper>
        }
      />
    </>
  )
}

const getOptimizationSpecialistDashboardRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  if (checkFeatureFlag('hierarchyOptimizationSpecialistDashboard')) {
    return getLocationBasedNavigationRoutes({
      key: 'optimization-specialist-dashboard-routes',
      context,
      permissions,
      basePath: OPTIMIZATION_SPECIALIST_DASHBOARD,
      permission: 'VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD',
      areaRouteElement: <AreaOptimizationSpecialistDashboard />,
      plantRouteElement: (
        <ConfigProviderWrapper>
          <OptimizationSpecialistDashboard />
        </ConfigProviderWrapper>
      )
    })
  }

  return (
    <>
      <Route
        key="optimization-specialist-dashboard-plant-select"
        path={OPTIMIZATION_SPECIALIST_DASHBOARD}
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantSelectByPermission
              path={PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD}
              permission="VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD"
            />
          </Wrapper>
        }
      />
      <Route
        path={PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD}
        key="optimization-specialist-dashboard"
        element={
          <Wrapper context={context} permissions={permissions}>
            <PlantPermissionGuardedRoute
              permission="VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD"
              plantSelectPath={OPTIMIZATION_SPECIALIST_DASHBOARD}
            >
              <ConfigProviderWrapper>
                <OptimizationSpecialistDashboard />
              </ConfigProviderWrapper>
            </PlantPermissionGuardedRoute>
          </Wrapper>
        }
      />
    </>
  )
}

const getLocationBasedNavigationRoutes = ({
  key,
  context,
  permissions,
  permission,
  basePath,
  areaRouteElement,
  plantRouteElement
}: {
  key: string
  context: Context
  permissions: Permission[]
  basePath: string
  permission: PermissionType
  areaRouteElement: React.ReactNode
  plantRouteElement: React.ReactNode
}) => [
  <Route
    key={key}
    path={basePath}
    element={
      <Wrapper context={context} permissions={permissions}>
        <Outlet />
      </Wrapper>
    }
  >
    <Route index element={<HierarchyLocationAccessRedirect permission={permission} />} />
    <Route path={HierarchyRouteSegment.AREA}>
      <Route
        index
        element={
          <AreaSelectByPermission
            path={`${HierarchyRouteSegment.AREA}/:areaId`}
            permission={permission}
          />
        }
      />
      <Route index path=":areaId" element={areaRouteElement} />
      <Route path=":areaId">
        <Route path={HierarchyRouteSegment.PLANT}>
          <Route
            index
            element={
              <AreaPlantSelectByPermission
                path={`${HierarchyRouteSegment.PLANT}/:plantId`}
                permission={permission}
                areaSelectPath={'../..'}
              />
            }
          />
          <Route
            path=":plantId"
            element={
              <PlantPermissionGuardedRoute permission={permission} plantSelectPath={'..'}>
                {plantRouteElement}
              </PlantPermissionGuardedRoute>
            }
          />
        </Route>
      </Route>
    </Route>
    <Route path={HierarchyRouteSegment.PLANT}>
      <Route
        index
        element={
          <PlantSelectByPermission
            path={`${HierarchyRouteSegment.PLANT}/:plantId`}
            permission={permission}
          />
        }
      />
      <Route
        path=":plantId"
        element={<HierarchyPlantAccessRedirect permission={permission} basePath={basePath} />}
      />
    </Route>
  </Route>
]
