import {CommonComment} from '@hconnect/common/comments'
import {ShiftEventCommentDto} from '@hconnect/common/types'
import moment from 'moment-timezone'

export const mapShiftEventCommentsToCommonComment = (
  comments: ShiftEventCommentDto[] | undefined,
  timezone: string
): CommonComment[] =>
  comments
    ? comments.map((comment) => ({
        id: comment.id,
        text: comment.description,
        createdBy: comment.creator,
        createdOn: moment.utc(comment.createDate).tz(timezone)
      }))
    : []
