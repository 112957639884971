import {PlantViewOption, PlantViewOptions} from '@hconnect/common/consts'
import {useSearchParams} from 'react-router-dom'

import {getUrlParam, setOrDeleteStringParam} from '../../shared/helpers'
import {RcfaComplianceQuickSelectionIds} from '../types'

const RcfaCompliancePageParams = {
  PLANT_VIEW: 'plantView',
  TIME_RANGE: 'timeRange'
}

export const useRcfaCompliancePageState = () => {
  const plantViewState = usePlantViewState()
  const timeRangeState = useTimeRangeParam()

  return {...plantViewState, ...timeRangeState}
}

const usePlantViewState = (defaultValue: PlantViewOption = PlantViewOptions.REGIONAL_STRUCTURE) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const plantViewParam = getUrlParam(searchParams, RcfaCompliancePageParams.PLANT_VIEW)
  const plantView = isPlantViewOption(plantViewParam) ? plantViewParam : defaultValue

  const setPlantView = (value: PlantViewOption) => {
    const newParams = new URLSearchParams(searchParams)

    setOrDeleteStringParam(newParams, RcfaCompliancePageParams.PLANT_VIEW, value)
    setSearchParams(newParams)
  }

  return {plantView, setPlantView}
}

const isPlantViewOption = (value: string | undefined): value is PlantViewOption =>
  Object.values(PlantViewOptions).includes(value as PlantViewOption)

const useTimeRangeParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const timeRangeParam = getUrlParam(searchParams, RcfaCompliancePageParams.TIME_RANGE)

  const setTimeRangeParam = (value: string) => {
    const newParams = new URLSearchParams(searchParams)

    setOrDeleteStringParam(newParams, RcfaCompliancePageParams.TIME_RANGE, value)
    setSearchParams(newParams)
  }

  return {
    timeRange: timeRangeParam ?? RcfaComplianceQuickSelectionIds.ALL,
    setTimeRange: setTimeRangeParam
  }
}
