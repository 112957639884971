export enum IconSize {
  Small = 13,
  Medium = 14,
  Large = 20
}

export enum PerformanceMapMarkerType {
  Success = 'success',
  Error = 'error',
  GrindingUnitOnly = 'grindingUnitOnly',
  NoPxTrend = 'noPxTrend',
  NoData = 'noData'
}
