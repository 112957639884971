import {useCallback, useMemo} from 'react'

import {Bounds, Point} from '../types'

type UseMapCoordinatesOptions = {
  priorityBounds: Bounds
  displayBounds: Bounds
  plantLocationMap: Record<string, Point>
}

export const useMapCoordinates = (options: UseMapCoordinatesOptions) => {
  const {priorityBounds, displayBounds, plantLocationMap} = options
  const {topLeft: priorityTopLeft, bottomRight: priorityBottomRight} = priorityBounds
  const priorityWidth = priorityBottomRight.x - priorityTopLeft.x
  const priorityHeight = priorityBottomRight.y - priorityTopLeft.y
  const {topLeft: displayTopLeft, bottomRight: displayBottomRight} = displayBounds
  const displayWidth = displayBottomRight.x - displayTopLeft.x
  const displayHeight = displayBottomRight.y - displayTopLeft.y

  // Compute the scale factor so that priority boundaries in the SVG (in SVG units)
  // will scale exactly to fill the display rectangle in the container.
  const scale = useMemo(() => {
    return Math.min(displayWidth / priorityWidth, displayHeight / priorityHeight)
  }, [displayWidth, displayHeight, priorityWidth, priorityHeight])

  // Compute translation offsets so that the priority boundaries map to display boundaries.
  // We want: (priority.x, prioriry.y) * scale + translation = (display.x, display.y)
  const translate = useMemo(() => {
    return {
      x: displayTopLeft.x - priorityTopLeft.x * scale,
      y: displayTopLeft.y - priorityTopLeft.y * scale
    }
  }, [displayTopLeft, priorityTopLeft, scale])

  const translateMarkerCoords = useCallback(
    (coords: Point) => ({
      x: translate.x + coords.x * scale,
      y: translate.y + coords.y * scale
    }),
    [translate, scale]
  )

  const getMarkerPosition = useCallback(
    (plantId: string) => {
      const plantLocation = plantLocationMap[plantId]

      return plantLocation && translateMarkerCoords(plantLocation)
    },
    [plantLocationMap, translateMarkerCoords]
  )

  return {
    scale,
    translate,
    getMarkerPosition
  }
}
