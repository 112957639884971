import {getTranslationKey} from '@hconnect/common/utils'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {stringify} from 'qs'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../shared/hooks'
import {TaskSource} from '../../../shared/types'
import {ShiftTasksSummary} from '../../types'

type Props = {
  plantId: string
  startDate: string
  endDate: string
}

const QueryKey = 'shift-tasks-plant-summary'

export const useShiftTasksSummary = ({plantId, startDate, endDate}: Props) => {
  const {t} = useTranslation()
  const axiosInstance = useAxios()
  const {enqueueSnackbar} = useSnackbar()
  const {hrocPrefix} = useTranslationPrefix()

  return useQuery<ShiftTasksSummary, AxiosError>({
    queryKey: [QueryKey, plantId, startDate, endDate],
    queryFn: async () => {
      const response = await axiosInstance.get<ShiftTasksSummary>(
        `${ApiPath.TASKS}/${plantId}/summary`,
        {
          params: {from: startDate, to: endDate, sources: [TaskSource.COCKPIT]},
          paramsSerializer: (params: string[]) => stringify(params, {arrayFormat: 'repeat'})
        }
      )

      return response.data
    },
    onError: () => {
      enqueueSnackbar(
        t(getTranslationKey('shiftTasksSummary.message.failedToFetchShiftTasks', hrocPrefix)),
        {
          variant: 'error'
        }
      )
    }
  })
}
