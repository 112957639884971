import {HierarchyNodeType} from '../../shared/types'

export const RcfaComplianceQuickSelectionIds = {
  ALL: 'all',
  YEAR_TO_DATE_YTD: 'yearToDateYTD',
  LAST_YEAR: 'lastYear'
} as const

export type RcfaComplianceQuickSelectionId =
  (typeof RcfaComplianceQuickSelectionIds)[keyof typeof RcfaComplianceQuickSelectionIds]

export type RcfaComplianceData = {
  completedCount: number
  totalMandatoryCount: number
  unresolvedTasksCount: number
  overdueTasksCount: number
}

export type RcfaComplianceLocationNodeDto = {
  id: string
  name: string
  type: HierarchyNodeType
  children: RcfaComplianceLocationNodeDto[]
  rcfaCompliance?: RcfaComplianceData
  countryCode?: string
}

export type RcfaComplianceHierarchyDto = RcfaComplianceLocationNodeDto

export type RcfaComplianceLocationNode = {
  id: string
  name: string
  type: HierarchyNodeType
  subLocations: RcfaComplianceLocationNode[]
  rcfaCompliance?: RcfaComplianceData
  countryCode?: string
}

export type RcfaComplianceTableData = {
  id: string
  type: HierarchyNodeType
  name: string
  countryCode?: string
  rcfaCompliance?: RcfaComplianceData
  subItems: RcfaComplianceTableData[]
}
