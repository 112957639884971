import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {useState} from 'react'

import {PlantMapData} from '../../hooks'
import {Bounds} from '../../types'

import {CursorPosition, RegionTooltip} from './AreaRegionTooltip'

type Props = {
  name: string
  defsConfigs: {clipPathId: string; path: string}[]
  bounds: Bounds
  data: PlantMapData[]
}

export const AreaRegion = ({defsConfigs, bounds, data, name}: Props) => {
  const [hovered, setHovered] = useState(false)
  const [cursorPos, setCursorPos] = useState<CursorPosition>({x: 0, y: 0})

  return (
    <>
      <defs>
        {defsConfigs.map(({clipPathId, path}) => (
          <clipPath key={clipPathId} id={clipPathId}>
            <path d={path} />
          </clipPath>
        ))}
      </defs>
      {defsConfigs.map(({clipPathId}) => (
        <g
          key={clipPathId}
          clipPath={`url(#${clipPathId})`}
          onMouseEnter={() => {
            setHovered(true)
          }}
          onMouseMove={(e) => {
            setCursorPos({x: e.clientX, y: e.clientY})
          }}
          onMouseLeave={() => {
            setHovered(false)
          }}
          style={{pointerEvents: 'all'}}
        >
          <rect
            x={bounds.topLeft.x}
            y={bounds.topLeft.y}
            width={bounds.bottomRight.x - bounds.topLeft.x}
            height={bounds.bottomRight.y - bounds.topLeft.y}
            fill={hovered ? customThemeConstants().palette.mapAreaFill : 'none'}
            stroke={customThemeConstants().palette.mapAreaStroke}
            strokeWidth={2}
            strokeDasharray="6 6"
          />
        </g>
      ))}

      <RegionTooltip hovered={hovered} cursorPos={cursorPos} name={name} data={data} />
    </>
  )
}
