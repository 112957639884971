import {FlexPage} from '@hconnect/common/components'
import {mapToFlatListWithKey} from '@hconnect/common/utils'
import {Grid, Stack, useMediaQuery, Theme} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getSystemTimezone, useTranslationPrefix} from '../../shared/hooks'
import {RcfaComplianceHeaderActions} from '../components'
import {RcfaComplianceContainer} from '../containers'
import {useExpandedState, useRcfaCompliance, useRcfaCompliancePageState} from '../hooks'
import {convertToLocationNodes} from '../mappers'
import {RcfaComplianceLocationNode} from '../types'

function getAllItemsIds(data?: RcfaComplianceLocationNode[]) {
  return mapToFlatListWithKey('subLocations', data ?? []).map((entry) => entry.id)
}

export const RcfaCompliancePage = () => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {plantView, setPlantView, timeRange, setTimeRange} = useRcfaCompliancePageState()
  const timezone = getSystemTimezone()

  const {data} = useRcfaCompliance(
    {
      timezone,
      timeRange
    },
    {
      select: convertToLocationNodes,
      refetchOnMount: false // we are fetching data in the container, here we only need items for expanded state
    }
  )

  const allItemsIds = useMemo(() => getAllItemsIds(data), [data])
  const {allExpanded, getIsExpanded, toggleExpandCollapse, toggleExpandCollapseAll} =
    useExpandedState(allItemsIds)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <FlexPage
      title={t(`${hrocPrefix}.rcfaCompliance.pageTitle`)}
      nameBodySx={{
        width: '100%'
      }}
      headerActionContent={
        !isMobile ? (
          <Grid spacing={1} container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <RcfaComplianceHeaderActions
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                allExpanded={allExpanded}
                toggleExpandCollapseAll={toggleExpandCollapseAll}
                plantView={plantView}
                setPlantView={setPlantView}
                timezone={timezone}
              />
            </Grid>
          </Grid>
        ) : null
      }
    >
      <Stack sx={{width: '100%'}} spacing={2}>
        {isMobile && (
          <RcfaComplianceHeaderActions
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            allExpanded={allExpanded}
            toggleExpandCollapseAll={toggleExpandCollapseAll}
            plantView={plantView}
            setPlantView={setPlantView}
            timezone={timezone}
            isMobile={isMobile}
          />
        )}
        <RcfaComplianceContainer
          plantView={plantView}
          timeRange={timeRange}
          timezone={timezone}
          getIsExpanded={getIsExpanded}
          toggleExpandCollapse={toggleExpandCollapse}
        />
      </Stack>
    </FlexPage>
  )
}
