import {MapAreaConfig} from '../types'

import {CANADA_BORDERS_PATH, US_BORDERS_PATH} from './map-path-defs'

export const SUPPORTED_MAP_AREAS = ['a_NAM']

export const NAM_AREA_CONFIG: MapAreaConfig = {
  bordersPaths: [
    {id: 'usa-map-layer', path: US_BORDERS_PATH},
    {id: 'canada-map-layer', path: CANADA_BORDERS_PATH}
  ],
  priorityBounds: {
    topLeft: {x: 0, y: 0},
    bottomRight: {x: 890, y: 622}
  },
  subRegions: [
    {
      name: 'Northwest Region',
      id: 'c_CAN_NWR',
      defsConfigs: [
        {clipPathId: 'nwr-canada-clip-path', path: CANADA_BORDERS_PATH},
        {clipPathId: 'nwr-usa-clip-path', path: US_BORDERS_PATH}
      ],
      bounds: {topLeft: {x: -50, y: 50}, bottomRight: {x: 250, y: 200}}
    },
    {
      name: 'Northeast Region',
      id: 'c_US_NER',
      defsConfigs: [
        {clipPathId: 'ner-usa-clip-path', path: US_BORDERS_PATH},
        {clipPathId: 'ner-canada-clip-path', path: CANADA_BORDERS_PATH}
      ],
      bounds: {topLeft: {x: 675, y: 225}, bottomRight: {x: 930, y: 410}}
    },
    {
      name: 'Midwest Region',
      id: 'c_US_MWR',
      defsConfigs: [{clipPathId: 'mwr-usa-clip-path', path: US_BORDERS_PATH}],
      bounds: {topLeft: {x: 475, y: 315}, bottomRight: {x: 670, y: 410}}
    },
    {
      name: 'Southeast Region',
      id: 'c_US_SR',
      defsConfigs: [{clipPathId: 'sr-usa-clip-path', path: US_BORDERS_PATH}],
      bounds: {topLeft: {x: 536, y: 415}, bottomRight: {x: 1000, y: 700}}
    }
  ],
  plantLocationMap: {
    C102: {x: 740, y: 270},
    C203: {x: 225, y: 75},
    C205: {x: 75, y: 145},
    U007: {x: 680, y: 555},
    U020: {x: 445, y: 455},
    U100: {x: 595, y: 460},
    U116: {x: 680, y: 555},
    U137: {x: 775, y: 355},
    U145: {x: 750, y: 335},
    U150: {x: 785, y: 280},
    U155: {x: 605, y: 335},
    U157: {x: 500, y: 380},
    U158: {x: 660, y: 340},
    U159: {x: 580, y: 365},
    U160: {x: 775, y: 320},
    U174: {x: 615, y: 385},
    U175: {x: 720, y: 365},
    U256: {x: 75, y: 185}
  }
}
