import {PlantSelectDropdown} from '@hconnect/common/components'
import plants from '@hconnect/common/hproduce/plants.json'
import {AppName} from '@hconnect/common/hproduce/types'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Shell} from '../../shared/components/Shell'
import {Context} from '../../shared/types/temp.types'

type AppProps = {
  appName: AppName
  context: Context
}

export const UnauthorizedApp: React.FC<AppProps> = ({appName, context}) => {
  const {t} = useTranslation()
  return (
    <Shell context={context} appName={appName}>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        flexGrow={1}
        alignContent="center"
      >
        {t('entryPoint.unauthorizedApp', {appName})}
      </Box>
    </Shell>
  )
}

type PlantProps = {
  appName: AppName
  plantId: string
  context: Context
}

export const UnauthorizedPlant: React.FC<PlantProps> = ({appName, plantId, context}) => {
  const plantName = plants[plantId]?.name || plantId
  const {t} = useTranslation()

  const allowedPlants = context.whiteList.optionsPerApp[appName] || []
  return (
    <Shell context={context} appName={appName}>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        flexGrow={1}
        alignContent="center"
      >
        {t('entryPoint.unauthorizedPlant', {appName, plantName})}
        <PlantSelectDropdown plants={allowedPlants} translationPrefix="entryPoint" />
      </Box>
    </Shell>
  )
}
