import {getTranslationKey} from '@hconnect/common/utils'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../shared/hooks'
import {EventsSummary} from '../types'

type Props = {
  plantId: string
  startDate: string
  endDate: string
}

const QueryKey = 'shift-events-count-per-category'

export const useEventsSummary = ({plantId, startDate, endDate}: Props) => {
  const {t} = useTranslation()
  const axiosInstance = useAxios()
  const {enqueueSnackbar} = useSnackbar()
  const {hrocPrefix} = useTranslationPrefix()

  return useQuery<EventsSummary, AxiosError>({
    queryKey: [QueryKey, plantId, startDate, endDate],
    queryFn: async () => {
      const response = await axiosInstance.get<EventsSummary>(
        `${ApiPath.SHIFT_HANDOVER}/${plantId}/search/events/count-per-category`,
        {params: {startDate, endDate}}
      )

      return response.data
    },
    onError: () => {
      enqueueSnackbar(
        t(getTranslationKey('eventsSummary.message.failedToFetchEvents', hrocPrefix)),
        {
          variant: 'error'
        }
      )
    }
  })
}
