import {Box} from '@mui/material'

type LayoutProps = {
  mapContainerRef: React.RefCallback<Element>
  mapSlot: React.ReactNode
  cardsSlot: React.ReactNode
  cardsSlotWidth: number
}

export const MapWithCardsDesktopLayout: React.FC<LayoutProps> = ({
  mapSlot,
  cardsSlot,
  cardsSlotWidth,
  mapContainerRef
}) => {
  return (
    <Box
      ref={mapContainerRef}
      sx={{
        maxHeight: '100%',
        width: '100%',
        position: 'relative'
      }}
    >
      {mapSlot}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          maxHeight: '100%',
          overflowY: 'auto',
          pr: 1,
          width: cardsSlotWidth
        }}
      >
        {cardsSlot}
      </Box>
    </Box>
  )
}
