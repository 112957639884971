import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import {alpha, lighten, Theme} from '@mui/material'
import {Palette} from '@mui/material/styles/createPalette'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import {forwardRef, ReactNode} from 'react'

import {IconSize, PerformanceMapMarkerType} from './maps.types'

const OUTLINE_WIDTH_IN_PX = 6
const COMMON_SX: SystemStyleObject<Theme> = {
  borderRadius: '50%'
}
const generateShadow = (color: string) => `0 0 0 ${OUTLINE_WIDTH_IN_PX}px ${color}`
const performanceMapMarkerIconSize: Record<PerformanceMapMarkerType, ReactNode> = {
  [PerformanceMapMarkerType.Success]: IconSize.Small,
  [PerformanceMapMarkerType.Error]: IconSize.Large,
  [PerformanceMapMarkerType.GrindingUnitOnly]: IconSize.Medium,
  [PerformanceMapMarkerType.NoPxTrend]: IconSize.Medium,
  [PerformanceMapMarkerType.NoData]: IconSize.Medium
}
const getPerformanceMapMarkerSx = (
  palette: Palette,
  type: PerformanceMapMarkerType,
  size?: IconSize,
  active = true
) => ({
  ...COMMON_SX,
  fontSize: `${size || performanceMapMarkerIconSize[type]}px`,
  color: alpha(palette.common.white, 0.6),
  ...(active && {
    cursor: 'pointer',
    backgroundColor: alpha(palette.common.white, 0.1),
    boxShadow: generateShadow(alpha(palette.common.white, 0.1)),
    '&:hover': {
      color: alpha(palette.common.black, 0.6),
      boxShadow: generateShadow(palette.common.white),
      backgroundColor: 'common.white'
    }
  })
})

type PerformanceMapIconProps = {
  type: PerformanceMapMarkerType
  size?: IconSize
  active?: boolean
  onClick?: () => void
}
export const PerformanceMapIcon = forwardRef<SVGSVGElement, PerformanceMapIconProps>(
  ({type, size, active = true, ...rest}, ref) => {
    switch (type) {
      case PerformanceMapMarkerType.Success:
        return (
          <CheckCircleIcon
            {...rest}
            ref={ref}
            viewBox={'2 2 20 20'}
            sx={({palette}) => ({
              ...COMMON_SX,
              color: 'success.light',
              fontSize: `${size || performanceMapMarkerIconSize[type]}px`,
              ...(active && {
                cursor: 'pointer',
                boxShadow: generateShadow(alpha(lighten(palette.info.main, 0.4), 0.2)),
                '&:hover': {
                  color: 'success.main',
                  boxShadow: generateShadow(palette.common.white),
                  backgroundColor: 'common.white'
                }
              })
            })}
          />
        )
      case PerformanceMapMarkerType.Error:
        return (
          <ErrorIcon
            {...rest}
            ref={ref}
            viewBox={'2 2 20 20'}
            sx={({palette}) => ({
              ...COMMON_SX,
              color: lighten(palette.error.light, 0.3),
              fontSize: `${size || performanceMapMarkerIconSize[type]}px`,
              ...(active && {
                cursor: 'pointer',
                boxShadow: generateShadow(alpha(lighten(palette.error.light, 0.4), 0.4)),
                '&:hover': {
                  color: lighten(palette.error.light, 0.2),
                  boxShadow: generateShadow(palette.common.white),
                  backgroundColor: 'common.white'
                }
              })
            })}
          />
        )
      case PerformanceMapMarkerType.GrindingUnitOnly:
        return (
          <LinkOffIcon
            {...rest}
            ref={ref}
            sx={({palette}) => getPerformanceMapMarkerSx(palette, type, size, active)}
          />
        )
      case PerformanceMapMarkerType.NoPxTrend:
        return (
          <CloudOffIcon
            {...rest}
            ref={ref}
            sx={({palette}) => getPerformanceMapMarkerSx(palette, type, size, active)}
          />
        )
      case PerformanceMapMarkerType.NoData:
        return (
          <InfoIcon
            {...rest}
            ref={ref}
            viewBox={'2 2 20 20'}
            sx={({palette}) => getPerformanceMapMarkerSx(palette, type, size, active)}
          />
        )
      default:
        return null
    }
  }
)
