import {useCallback, useMemo} from 'react'

import {findLocationEntry, getLocationParent} from '../../shared/helpers'
import {useEquipmentStatus, useHierarchy} from '../../shared/hooks'
import {
  PerformanceDashboardEquipmentTypes,
  PxTrendMetaData,
  PlantStatusEquipment
} from '../../shared/types'

export type PlantMapData = {
  plantId: string
  parentId?: string
  plantName: string
  equipments: PlantStatusEquipment[]
  pxTrendMetaData?: PxTrendMetaData
}

export const useMapMarkersData = (locationId?: string) => {
  const {
    data: equipmentStatusData = {},
    isInitialLoading: equipmentStatusIsInitialLoading,
    isError: isEquipmentStatusError,
    refetch: equipmentStatusRefetch
  } = useEquipmentStatus(
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).toJSON(),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)).toJSON()
    },
    locationId,
    {enabled: !!locationId}
  )
  const {
    data: hierarchy,
    isInitialLoading: hierarchyIsInitialLoading,
    isError: isHierarchyError,
    refetch: hierarchyRefetch
  } = useHierarchy({enabled: !!locationId})

  const retry = useCallback(() => {
    if (isEquipmentStatusError) void equipmentStatusRefetch()
    if (isHierarchyError) void hierarchyRefetch()
  }, [equipmentStatusRefetch, hierarchyRefetch, isEquipmentStatusError, isHierarchyError])

  const data: PlantMapData[] = useMemo(
    () =>
      hierarchy
        ? Object.entries(equipmentStatusData).reduce<PlantMapData[]>(
            (arr, [plantId, plantEquipmentStatus]) => {
              const plant = findLocationEntry(hierarchy, plantId)
              if (plant) {
                const equipments = plantEquipmentStatus.equipments.filter(
                  (equipment) => equipment.type === PerformanceDashboardEquipmentTypes.KILN
                )
                const parent = getLocationParent(hierarchy, plantId)

                arr.push({
                  plantId,
                  parentId: parent?.id,
                  plantName: plant.name,
                  equipments,
                  pxTrendMetaData: plant.pxTrendMetaData
                })
              }
              return arr
            },
            []
          )
        : [],
    [equipmentStatusData, hierarchy]
  )

  return {
    data,
    isInitialLoading: equipmentStatusIsInitialLoading || hierarchyIsInitialLoading,
    isError: isEquipmentStatusError || isHierarchyError,
    refetch: retry
  }
}
