import {FlexPage} from '@hconnect/common/components'
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'

import {checkFeatureFlag} from '../../../shared/helpers'
import {useTranslationPrefix} from '../../../shared/hooks'
import {LocationBreadcrumbs} from '../../components'
import {cardBoxSizes, placeholderWrapperSizes} from '../../consts'
import {
  OperationHoursContainer,
  ProcessPlantStatusDailyKpisContainer,
  ProcessPlantStatusKpisContainer,
  MyTasksContainer
} from '../../containers'
import {getProcessEngineerStoppageAnalysisPagePath} from '../../utils'

type PathParameter = {
  plantId?: string
}

export const ProcessEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const navigate = useNavigate()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const showLocationBreadcrumbs = checkFeatureFlag('hierarchyProcessEngineerDashboard')

  const redirectToStoppageAnalysis = (equipmentId: string) => {
    navigate(getProcessEngineerStoppageAnalysisPagePath(plantId, equipmentId))
  }

  return (
    <FlexPage
      title={t(`${hrocPrefix}.processEngineerDashboard.pageTitle`)}
      aboveTitle={showLocationBreadcrumbs ? <LocationBreadcrumbs /> : undefined}
      nameHeaderSx={showLocationBreadcrumbs ? {mt: 0} : undefined}
    >
      {isMobile ? (
        <MobileLayout
          plantStatusKpis={<ProcessPlantStatusDailyKpisContainer plantId={plantId} />}
          operationHours={
            <OperationHoursContainer
              plantId={plantId}
              onEquipmentClick={redirectToStoppageAnalysis}
              isMobile={true}
            />
          }
          myTasks={
            <MyTasksContainer
              plantId={plantId}
              cardBoxSx={{width: '100%'}}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], width: '100%'}}
            />
          }
        />
      ) : (
        <DesktopLayout
          plantStatusKpis={<ProcessPlantStatusKpisContainer plantId={plantId} />}
          operationHours={
            <OperationHoursContainer
              plantId={plantId}
              onEquipmentClick={redirectToStoppageAnalysis}
              cardBoxSx={cardBoxSizes['M']}
            />
          }
          myTasks={
            <MyTasksContainer
              plantId={plantId}
              cardBoxSx={cardBoxSizes['S']}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], height: '100%'}}
            />
          }
        />
      )}
    </FlexPage>
  )
}

type LayoutProps = {
  plantStatusKpis: React.ReactNode
  operationHours: React.ReactNode
  myTasks: React.ReactNode
}

const DesktopLayout: React.FC<LayoutProps> = ({plantStatusKpis, operationHours, myTasks}) => {
  return (
    <Stack spacing={2} useFlexGap flex={1}>
      {plantStatusKpis}
      <Stack direction="row" spacing={2} flex={1} useFlexGap>
        {operationHours}
        <Stack spacing={2}>
          {myTasks}
          {/* TODO: HCP-82238 Expert System Utilization */}
        </Stack>
      </Stack>
    </Stack>
  )
}

const MobileLayout: React.FC<LayoutProps> = ({plantStatusKpis, operationHours, myTasks}) => {
  return (
    <Stack spacing={1} useFlexGap flex={1}>
      {plantStatusKpis}
      {operationHours}
      <Stack spacing={1} direction="row" flex={1} useFlexGap flexWrap="wrap">
        <Box sx={{display: 'flex', flex: '1 0 0'}}>{myTasks}</Box>
        {/* TODO: HCP-82238 Expert System Utilization */}
      </Stack>
    </Stack>
  )
}
