import moment from 'moment'

import type {
  KpiTrendChartData,
  MonthlyAggregatedPlantProductionKpisDto,
  PlantStatusMontlyKpiTrends
} from '../types'

export const mapToPlantStatusMontlyKpiTrends = (
  data?: MonthlyAggregatedPlantProductionKpisDto[]
): PlantStatusMontlyKpiTrends => {
  const clinkerProduction: KpiTrendChartData = {labels: [], data: []}
  const cementProduction: KpiTrendChartData = {labels: [], data: []}
  const meanTimeBetweenFailure: KpiTrendChartData = {labels: [], data: []}
  const kilnOperatingCoefficient: KpiTrendChartData = {labels: [], data: []}
  const alternativeFuelRate: KpiTrendChartData = {labels: [], data: []}
  const kilnReliabilityCoefficient: KpiTrendChartData = {labels: [], data: []}

  data?.forEach((item) => {
    const label = moment.utc([item.year, item.month - 1]).toISOString()
    clinkerProduction.labels.push(label)
    cementProduction.labels.push(label)
    meanTimeBetweenFailure.labels.push(label)
    kilnOperatingCoefficient.labels.push(label)
    alternativeFuelRate.labels.push(label)
    kilnReliabilityCoefficient.labels.push(label)

    clinkerProduction.data.push(item.monthlyAggregatedActualProduction?.clinkerProduction ?? 0)
    cementProduction.data.push(item.monthlyAggregatedActualProduction?.cementProduction ?? 0)
    meanTimeBetweenFailure.data.push(
      item.monthToMonthKpiValues?.meanTimeBetweenFailure?.actual ?? 0
    )
    kilnOperatingCoefficient.data.push(
      item.monthToMonthKpiValues?.weightedKilnOperatingCoefficient?.actual ?? 0
    )
    alternativeFuelRate.data.push(item.monthToMonthKpiValues?.alternativeFuelRate?.actual ?? 0)
    kilnReliabilityCoefficient.data.push(
      item.monthToMonthKpiValues?.kilnReliabilityCoefficient?.actual ?? 0
    )
  })

  return {
    clinkerProduction,
    cementProduction,
    meanTimeBetweenFailure,
    kilnOperatingCoefficient,
    alternativeFuelRate,
    kilnReliabilityCoefficient
  }
}

export const mapLastMonthAreaReliabilityEngineerKpis = (
  monthlyKpis?: MonthlyAggregatedPlantProductionKpisDto
) => {
  return {
    clinkerProduction: monthlyKpis?.monthlyAggregatedActualProduction.clinkerProduction,
    cementProduction: monthlyKpis?.monthlyAggregatedActualProduction.cementProduction,
    weightedKilnOperatingCoefficient:
      monthlyKpis?.monthToMonthKpiValues?.weightedKilnOperatingCoefficient.actual,
    alternativeFuelRate: monthlyKpis?.monthToMonthKpiValues?.alternativeFuelRate.actual,
    meanTimeBetweenFailure: monthlyKpis?.monthToMonthKpiValues?.meanTimeBetweenFailure.actual,
    kilnReliabilityCoefficient:
      monthlyKpis?.monthToMonthKpiValues?.kilnReliabilityCoefficient.actual
  }
}
