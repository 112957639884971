import {KpiThresholdIcon} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiScore} from '@hconnect/uikit/src/lib2'
import {Grid, Theme, useMediaQuery} from '@mui/material'

import {NO_VALUE_STRING} from '../consts'
import {KpiDailyData} from '../types'

type Props = {
  kpiItems: KpiDailyData[]
  gridItemXs?: number
  kpiSize?: 'XL' | 'L' | 'M' | 'S' | 'XS'
  showKpiValueLoader?: boolean
}

const SINGLE_COLUMN_BREAKPOINT = 380

export const PlantStatusDailyKpis: React.FC<Props> = ({
  kpiItems,
  gridItemXs = 6,
  kpiSize = 'L',
  showKpiValueLoader
}: Props) => {
  const isSingleColumn = useMediaQuery(({breakpoints}: Theme) =>
    breakpoints.down(SINGLE_COLUMN_BREAKPOINT)
  )

  return (
    <Grid container spacing={1.5} sx={{maxWidth: '100%'}}>
      {kpiItems.map((kpiItem) => (
        <Grid item xs={isSingleColumn ? 12 : gridItemXs} key={kpiItem.key}>
          <KpiScore
            value={kpiItem.value ?? NO_VALUE_STRING}
            variant={kpiItem.value ? 'neutral' : 'no-value'}
            label={kpiItem.label}
            unit={kpiItem.unit}
            valueIcon={
              kpiItem.kpiBreachSeverity ? (
                <KpiThresholdIcon status={kpiItem.kpiBreachSeverity} variant="filled" />
              ) : undefined
            }
            size={kpiSize}
            showValueLoader={showKpiValueLoader}
            data-test-id={`kpi-score-${kpiItem.key}`}
          />
        </Grid>
      ))}
    </Grid>
  )
}
