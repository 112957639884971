import {PermissionType} from '@hconnect/apiclient'

import {useHierarchy} from '../../shared/hooks'
import {getAllowedLocations} from '../utils'

import {usePlantsByPermission} from './usePlantsByPermission'

export const useHierarchyNavAccess = (permission: PermissionType) => {
  const {data: hierarchy, isInitialLoading, isError, refetch} = useHierarchy()
  const {plantIds: allowedPlants} = usePlantsByPermission(permission)

  const {areas, plantsByArea} = getAllowedLocations(hierarchy, allowedPlants)
  const getPlantsByAreaId = (areaId: string) => plantsByArea[areaId] || []
  const getAreaById = (areaId: string) => areas[areaId] || null

  return {
    isInitialLoading,
    isError,
    refetch,
    allowedPlants,
    allowedAreas: Object.keys(areas),
    getPlantsByAreaId,
    getAreaById
  }
}
