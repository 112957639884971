import {FlexPage} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Grid, useMediaQuery, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {generatePath, Navigate, useNavigate, useParams} from 'react-router'
import useResizeObserver from 'use-resize-observer'

import {useTranslationPrefix} from '../../../shared/hooks'
import {LocationBreadcrumbs, AreaKilnStatusMap} from '../../components'
import {NAM_AREA_CONFIG, SUPPORTED_MAP_AREAS} from '../../consts'
import {ProcessLastMonthKpisContainer} from '../../containers'
import {useMapMarkersData, useHierarchyNavAccess} from '../../hooks'
import {MapWithCardsDesktopLayout, MapWithCardsMobileLayout} from '../../layouts'
import {HierarchyRouteSegment, PROCESS_ENGINEER_DASHBOARD} from '../../routing'
import {getDisplayBounds, isValidAreaId, plantIdsByPlantNameCompareFn} from '../../utils'
import {Error404Page} from '../Error404Page'

const DESCTOP_CARDS_SECTION_WIDTH = 660

const getMapWithSideCardsDisplayBounds = (width: number, height: number) =>
  getDisplayBounds(width - DESCTOP_CARDS_SECTION_WIDTH, height)

export const AreaProcessEngineerDashboard = () => {
  const {areaId} = useParams<{areaId: string}>()

  const navigate = useNavigate()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {allowedPlants} = useHierarchyNavAccess('VIEW_PROCESS_ENGINEER_DASHBOARD')
  const {data} = useMapMarkersData(areaId)
  const filteredMapData = data?.filter(({plantId}) => allowedPlants.includes(plantId)) ?? []
  const sortedPlantCardsIds = filteredMapData
    .map(({plantId}) => plantId)
    .sort(plantIdsByPlantNameCompareFn)
  const {ref: containerRef, width = 0, height = 0} = useResizeObserver()
  const {breakpoints} = useTheme()
  const isMedium = useMediaQuery(breakpoints.between('sm', 'lg'))
  const isSmall = useMediaQuery(breakpoints.down('sm'))

  const isMapContainerReady = width && height

  const handlePlantMarkerClick = (plantId: string) => {
    navigate(`${HierarchyRouteSegment.PLANT}/${plantId}`)
  }

  if (!isValidAreaId(areaId)) {
    return (
      <Error404Page
        goToLink={generatePath(PROCESS_ENGINEER_DASHBOARD)}
        goToCaption={t(getTranslationKey('action.backToTarget', hrocPrefix), {
          targetName: t(getTranslationKey('processEngineerDashboard.pageTitle', hrocPrefix))
        })}
      />
    )
  }

  if (!SUPPORTED_MAP_AREAS.includes(areaId)) {
    return <Navigate to={HierarchyRouteSegment.PLANT} />
  }

  const commonFlexPageProps = {
    title: t(`${hrocPrefix}.processEngineerDashboard.pageTitle`),
    aboveTitle: <LocationBreadcrumbs />,
    nameHeaderSx: {mt: 0}
  }

  if (isSmall) {
    return (
      <FlexPage {...commonFlexPageProps}>
        <PlantCardsList
          plantIds={sortedPlantCardsIds}
          columnsCount={1}
          kpiGridItemXs={6}
          spacing={1}
        />
      </FlexPage>
    )
  }

  if (isMedium) {
    return (
      <FlexPage {...commonFlexPageProps} nameBodySx={{px: 0, pb: 1}}>
        <MapWithCardsMobileLayout
          mapContainerRef={containerRef}
          mapSlot={
            isMapContainerReady && (
              <AreaKilnStatusMap
                width={width}
                height={height}
                mapAreaConfig={NAM_AREA_CONFIG}
                plantMapData={filteredMapData}
                displayBounds={getDisplayBounds(width, height)}
                onPlantMarkerClick={handlePlantMarkerClick}
              />
            )
          }
          cardsSlot={
            <PlantCardsList plantIds={sortedPlantCardsIds} columnsCount={2} kpiGridItemXs={6} />
          }
        />
      </FlexPage>
    )
  }

  return (
    <FlexPage {...commonFlexPageProps} useViewportHeight nameBodySx={{px: 0, pb: 0}}>
      <MapWithCardsDesktopLayout
        mapContainerRef={containerRef}
        mapSlot={
          isMapContainerReady && (
            <AreaKilnStatusMap
              width={width}
              height={height}
              mapAreaConfig={NAM_AREA_CONFIG}
              plantMapData={filteredMapData}
              displayBounds={getMapWithSideCardsDisplayBounds(width, height)}
              onPlantMarkerClick={handlePlantMarkerClick}
            />
          )
        }
        cardsSlot={
          <PlantCardsList plantIds={sortedPlantCardsIds} columnsCount={1} kpiGridItemXs={4} />
        }
        cardsSlotWidth={DESCTOP_CARDS_SECTION_WIDTH}
      />
    </FlexPage>
  )
}

type PlantCardsListProps = {
  plantIds: string[]
  spacing?: number
  columnsCount?: number
  kpiGridItemXs?: number
}

const COLUMN_SIZE = 1
const PlantCardsList = ({
  plantIds,
  spacing = 2,
  columnsCount = 1,
  kpiGridItemXs = 4
}: PlantCardsListProps) => {
  return (
    <Grid container spacing={spacing} columns={columnsCount * COLUMN_SIZE}>
      {plantIds.map((plantId) => (
        <Grid item xs={COLUMN_SIZE} key={plantId}>
          <ProcessLastMonthKpisContainer plantId={plantId} kpiGridItemXs={kpiGridItemXs} />
        </Grid>
      ))}
    </Grid>
  )
}
