import {alpha, Link, Typography, Breadcrumbs as MUIBreadcrumbs} from '@mui/material'
import {FC} from 'react'
import {Link as RouterLink} from 'react-router-dom'

export type Breadcrumb = {
  label: string
  path: string
}
type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({breadcrumbs}) => {
  return (
    <MUIBreadcrumbs separator={<b>|</b>}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1
        return isLast ? (
          <Typography key={breadcrumb.path} component="span" color="common.white">
            {breadcrumb.label}
          </Typography>
        ) : (
          <Link
            key={breadcrumb.path}
            component={RouterLink}
            to={breadcrumb.path}
            sx={{textDecoration: 'none', color: ({palette}) => alpha(palette.common.white, 0.6)}}
          >
            {breadcrumb.label}
          </Link>
        )
      })}
    </MUIBreadcrumbs>
  )
}
