import {FlexPage} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {generatePath, Navigate, useNavigate, useParams} from 'react-router'
import useResizeObserver from 'use-resize-observer'

import {useTranslationPrefix} from '../../../shared/hooks'
import {
  AreaMap,
  PerformanceMapMarker,
  BordersOverlay,
  AreaRegionOverlay,
  LocationBreadcrumbs
} from '../../components'
import {NAM_AREA_CONFIG, SUPPORTED_MAP_AREAS} from '../../consts'
import {useMapMarkersData, useMapCoordinates, useHierarchyNavAccess} from '../../hooks'
import {HierarchyRouteSegment, OPTIMIZATION_SPECIALIST_DASHBOARD} from '../../routing'
import {Error404Page} from '../Error404Page'

const isValidArea = (areaId?: string): areaId is string => {
  return !!areaId
}

const getDisplayBounds = (width: number, height: number) => ({
  topLeft: {x: 0, y: 0},
  bottomRight: {x: width, y: height}
})

export const AreaOptimizationSpecialistDashboard = () => {
  const {areaId} = useParams<{areaId: string}>()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {allowedPlants} = useHierarchyNavAccess('VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD')
  const {data} = useMapMarkersData(areaId)
  const filteredMapData = data?.filter(({plantId}) => allowedPlants.includes(plantId)) ?? []
  const {ref: containerRef, width, height} = useResizeObserver()
  const {translate, scale, getMarkerPosition} = useMapCoordinates({
    priorityBounds: NAM_AREA_CONFIG.priorityBounds,
    displayBounds: getDisplayBounds(width ?? 0, height ?? 0),
    plantLocationMap: NAM_AREA_CONFIG.plantLocationMap
  })

  if (!isValidArea(areaId)) {
    return (
      <Error404Page
        goToLink={generatePath(OPTIMIZATION_SPECIALIST_DASHBOARD)}
        goToCaption={t(getTranslationKey('action.backToTarget', hrocPrefix), {
          targetName: t(getTranslationKey('optimizationSpecialistDashboard.pageTitle', hrocPrefix))
        })}
      />
    )
  }

  if (!SUPPORTED_MAP_AREAS.includes(areaId)) {
    return <Navigate to={HierarchyRouteSegment.PLANT} />
  }

  return (
    <FlexPage
      title={t(`${hrocPrefix}.optimizationSpecialistDashboard.pageTitle`)}
      aboveTitle={<LocationBreadcrumbs />}
      useViewportHeight
      nameHeaderSx={{mt: 0}}
      nameBodySx={{px: 0}}
    >
      <Box
        ref={containerRef}
        sx={{
          maxHeight: '100%',
          width: '100%'
        }}
      >
        {width && height && (
          <AreaMap width={width} height={height}>
            {/* Order matters */}
            <AreaRegionOverlay
              width={width}
              height={height}
              scale={scale}
              translate={translate}
              subRegions={NAM_AREA_CONFIG.subRegions}
              data={filteredMapData}
            />
            <BordersOverlay
              bordersPaths={NAM_AREA_CONFIG.bordersPaths}
              width={width}
              height={height}
              scale={scale}
              translate={translate}
            />
            {filteredMapData?.map(({equipments, pxTrendMetaData, plantId, plantName}) => (
              <PerformanceMapMarker
                key={plantId}
                equipments={equipments}
                pxTrendMetaData={pxTrendMetaData}
                plantName={plantName}
                coordinate={getMarkerPosition(plantId)}
                onClick={() => navigate(`${HierarchyRouteSegment.PLANT}/${plantId}`)}
                dataTestId={`plant-marker-${plantId}`}
              />
            ))}
          </AreaMap>
        )}
      </Box>
    </FlexPage>
  )
}
