import {Popper, Stack, Typography} from '@mui/material'
import {FC} from 'react'

import {PlantMapData} from '../../hooks'

import {getMarkerType, PlantTooltipContent} from './PlantTooltipContent'

const OFFSET_MAP = {
  top: {x: 10, y: 5},
  bottom: {x: 15, y: 20},
  'bottom-end': {x: -5, y: 25},
  'top-end': {x: -5, y: 5},
  left: {x: -10, y: 0},
  right: {x: 20, y: 0},
  auto: {x: 0, y: 0}
}

export type CursorPosition = {
  x: number
  y: number
}

type RegionTooltipProps = {
  hovered: boolean
  cursorPos: CursorPosition
  data: PlantMapData[]
  name: string
}

export const RegionTooltip: FC<RegionTooltipProps> = ({hovered, cursorPos, data, name}) => {
  const virtualAnchor = {
    getBoundingClientRect: () => ({
      top: cursorPos.y,
      left: cursorPos.x,
      bottom: cursorPos.y,
      right: cursorPos.x,
      width: 0,
      height: 0
    })
  } as HTMLElement

  return (
    <Popper
      open={hovered}
      anchorEl={virtualAnchor}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: ({placement}) => {
              const placementProps = OFFSET_MAP[placement] as keyof typeof OFFSET_MAP
              if (placementProps) {
                return Object.values(placementProps)
              }
              const basePlacement = placement.split('-')[0] as keyof typeof OFFSET_MAP
              return Object.values(OFFSET_MAP[basePlacement])
            }
          }
        },
        {
          name: 'preventOverflow',
          options: {boundary: 'window', altAxis: true}
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-start', 'bottom-end', 'top-end']
          }
        }
      ]}
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        left: cursorPos.x,
        top: cursorPos.y
      }}
    >
      <Stack
        sx={{bgcolor: 'grey.900', color: 'common.white', p: 2, borderRadius: 1, fontSize: 14}}
        gap={2}
      >
        <Typography variant="h5">{name}</Typography>
        {data.map(({plantId, plantName, equipments, pxTrendMetaData}) => (
          <PlantTooltipContent
            key={plantId}
            headerVariant="body1"
            plantName={plantName}
            equipments={equipments}
            type={getMarkerType(equipments, pxTrendMetaData)}
          />
        ))}
      </Stack>
    </Popper>
  )
}
