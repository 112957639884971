import {AppName, AppPlantSelectOption} from '@hconnect/common/hproduce/types'
import React, {FC} from 'react'
import {Navigate, Route, useParams} from 'react-router-dom'

import {Context} from '../../shared/types/temp.types'
import {PlantSelectPage} from '../pages/PlantSelectPage'
import {UnauthorizedApp, UnauthorizedPlant} from '../pages/Unauthorized'

export type Props = {
  appName: AppName
  context: Context
  children: React.ReactNode
}

// TODO make the ProductRoute usable as a component
// export const ProductRoute: React.FC<Props> = ({appName, children, context}) => {
//   return ProductRouteHelper(appName, context, children)
// }

// <ProductRoute context={context} appName="Maintain">
//   <Suspense fallback={<div>Loading...</div>}>
//     <Sample />
//   </Suspense>
// </ProductRoute>

//     {/* Products with a redirect */}
//     <ProductRoute context={context} appName="Planner" />
//     <ProductRoute context={context} appName="Cockpit" />
//     <ProductRoute context={context} appName="EquipPulse" />

type ProductHelperProps = {
  appName: AppName
  context: Context
  options: AppPlantSelectOption[] | undefined
  renderComponent?: FC<{plant: {plantId: string; plantName: string}}>
}

const ProductHelper: FC<ProductHelperProps> = ({appName, context, options, renderComponent}) => {
  const params = useParams()

  // has the user access to the app?
  if (!options || options.length === 0) {
    return <UnauthorizedApp appName={appName} context={context} />
  }

  const lowerCasePlantId: string | undefined = params.plantId?.toLowerCase()

  // if no plant is specified
  if (!params.plantId) {
    if (options.length === 1) {
      // go to the only plant the user has access too
      return <Navigate to={`/${appName}/${options[0].plantId}`} />
    } else {
      // show plant select
      return <PlantSelectPage whiteList={context.whiteList} appName={appName} context={context} />
    }
  }

  const requestedOption = options.find(
    (option) => option.plantId.toLowerCase() === lowerCasePlantId
  )

  // has the user access to the requested plant
  if (!requestedOption) {
    return <UnauthorizedPlant appName={appName} plantId={params.plantId} context={context} />
  }

  // shall we render a component
  if (renderComponent && requestedOption) {
    // TODO add a Error boundary here to make sure that one crashing app dose not stop the rest from working
    return renderComponent({
      plant: {
        plantId: requestedOption.plantId,
        plantName: requestedOption.plantName
      }
    })
  }

  // or redirect to the configured url
  window.location.replace(requestedOption.url)
  return null // we could return a loading indicator here
}

export const ProductRouteHelper = (
  appName: AppName,
  context: Context,
  renderComponent?: FC<{plant: {plantId: string; plantName: string}}>
) => {
  const options: AppPlantSelectOption[] | undefined = context.whiteList.optionsPerApp[appName]

  return (
    <Route
      path={`/${appName}/:plantId?`}
      element={
        <ProductHelper
          context={context}
          appName={appName}
          options={options}
          renderComponent={renderComponent}
        />
      }
    />
  )
}
