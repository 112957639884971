import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix, usePlantTimezone} from '../../shared/hooks'
import {DataContentWrapper, OperationHoursList} from '../components'
import {useOperationHours, useOptimizationSpecialistTimeRange} from '../hooks'

type Props = {
  plantId: string
  onEquipmentClick: (equipmentId: string) => void
  cardBoxSx?: SxProps<Theme>
  isMobile?: boolean
  hideLegend?: boolean
}

const cardBoxPx = {xs: 2, md: 3}
const MIN_ITEMS_FOR_TWO_COLUMNS_VIEW = 5

export const OperationHoursTimeRangeContainer = ({
  plantId,
  onEquipmentClick,
  cardBoxSx,
  isMobile = false,
  hideLegend = false
}: Props) => {
  const {t} = useTranslation()
  const timezone = usePlantTimezone()
  const {hrocPrefix} = useTranslationPrefix()
  const {start, end} = useOptimizationSpecialistTimeRange()
  const {
    data: runningTimePerformances,
    isInitialLoading,
    isError,
    isRefetching,
    refetch
  } = useOperationHours({
    plantId,
    from: start,
    to: end
  })

  return (
    <CardBox
      sx={{
        ...cardBoxSx,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        px: 0,
        pl: cardBoxPx
      }}
      data-test-id="operation-hours-card"
    >
      <Box
        sx={{
          display: 'flex',
          pr: cardBoxPx
        }}
      >
        <Typography variant="h3" mb={2} display="flex" alignItems="center">
          {t(`${hrocPrefix}.operationHours.cardTitle`)}
          {hideLegend && <OperationHoursLegendTooltip translationPrefix={hrocPrefix} />}
        </Typography>
      </Box>
      <DataContentWrapper
        showLoader={isInitialLoading}
        isError={isError}
        isRefetching={isRefetching}
        data={runningTimePerformances}
        retryFunction={() => void refetch()}
        progressSx={{color: 'primary.main'}}
      >
        <Box sx={{flex: 1, overflowY: 'auto', overflowX: 'hidden', pr: cardBoxPx}}>
          <OperationHoursList
            runningTimePerformances={runningTimePerformances}
            from={start.toISOString()}
            to={end.toISOString()}
            timezone={timezone}
            translationPrefix={hrocPrefix}
            onEquipmentClick={onEquipmentClick}
            oneColumnView={
              isMobile ||
              (runningTimePerformances &&
                runningTimePerformances?.length < MIN_ITEMS_FOR_TWO_COLUMNS_VIEW)
            }
            hideLegend={hideLegend}
          />
        </Box>
      </DataContentWrapper>
    </CardBox>
  )
}
