import {Box, Stack} from '@mui/material'

import {SummaryScore} from './SummaryScore'

type Variant = 'neutral' | 'positive' | 'negative'

export type SummaryScore = {
  label: string
  value: number
  valueVariant: Variant
  tooltipText?: string
  showTooltip: boolean
  dataTestId: string
}

export const SummaryScores = ({items}: {items: SummaryScore[]}) => {
  return (
    <Stack direction="row" spacing={3} useFlexGap>
      {items.map((item, index) => (
        <StackItem key={index}>
          <SummaryScore
            label={item.label}
            value={item.value}
            valueVariant={item.valueVariant}
            showTooltip={item.showTooltip}
            tooltipText={item.tooltipText}
            dataTestId={item.dataTestId}
          />
        </StackItem>
      ))}
    </Stack>
  )
}

const StackItem: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box
    sx={{
      display: 'flex',
      flex: '1 0 0'
    }}
  >
    {children}
  </Box>
)
