import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {getSystemTimezone, useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, PlantStatusDailyKpis} from '../components'
import {useMonthlyAggregatedKpis} from '../hooks/api'
import {mapLastMonthAreaReliabilityEngineerKpis} from '../mappers'
import {HierarchyRouteSegment} from '../routing'
import {MonthlyAggregatedPlantProductionKpisDto} from '../types'
import {formatKpiValue} from '../utils'

type Props = {
  plantId: string
  kpiGridItemXs?: number
  cardBoxSx?: SxProps<Theme>
}

const AGGREGATION_MONTHS_COUNT = 1

const lastMonthKpisSelector = (data: MonthlyAggregatedPlantProductionKpisDto[]) =>
  mapLastMonthAreaReliabilityEngineerKpis(data.at(0))

export const ReliabilityLastMonthKpisContainer: React.FC<Props> = ({
  plantId,
  cardBoxSx,
  kpiGridItemXs = 4
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = getSystemTimezone()
  const to = moment.tz(timezone).startOf('month')
  const from = to.clone().subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {
    data: lastMonthKpis,
    isInitialLoading: areMonthlyKpiTrendsLoading,
    isError: isMontlyKpisError,
    isRefetching: isMonthlyKpisRefetching,
    refetch: refetchMonthlyKpis
  } = useMonthlyAggregatedKpis({plantId, from, to}, {select: lastMonthKpisSelector})

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(lastMonthKpis?.clinkerProduction?.toString(), language),
        unit: typeof lastMonthKpis?.clinkerProduction === 'number' ? 't' : undefined
      },
      {
        key: 'kiln-operating-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnOperatingCoefficient`),
        value: formatKpiValue(
          lastMonthKpis?.weightedKilnOperatingCoefficient?.toString(),
          language
        ),
        unit: typeof lastMonthKpis?.weightedKilnOperatingCoefficient === 'number' ? '%' : undefined
      },
      {
        key: 'alternative-fuel-rate',
        label: t(`${hrocPrefix}.plantStatus.label.alternativeFuelRate`),
        value: formatKpiValue(lastMonthKpis?.alternativeFuelRate?.toString(), language),
        unit: typeof lastMonthKpis?.alternativeFuelRate === 'number' ? '%' : undefined
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(lastMonthKpis?.cementProduction?.toString(), language),
        unit: typeof lastMonthKpis?.cementProduction === 'number' ? 't' : undefined
      },
      {
        key: 'kiln-mean-time-between-failure',
        label: t(`${hrocPrefix}.plantStatus.label.kilnMeanTimeBetweenFailure`),
        value: formatKpiValue(lastMonthKpis?.meanTimeBetweenFailure?.toString(), language),
        unit: typeof lastMonthKpis?.meanTimeBetweenFailure === 'number' ? 'h' : undefined
      },
      {
        key: 'kiln-reliability-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnReliabilityCoefficient`),
        value: formatKpiValue(lastMonthKpis?.kilnReliabilityCoefficient?.toString(), language),
        unit: typeof lastMonthKpis?.kilnReliabilityCoefficient === 'number' ? '%' : undefined
      }
    ],
    [lastMonthKpis, t, hrocPrefix, language]
  )

  return (
    <CardLinkWrapper<typeof Link> component={Link} to={`${HierarchyRouteSegment.PLANT}/${plantId}`}>
      <CardBox sx={cardBoxSx} data-test-id={`plant-status-kpis-card-${plantId}`}>
        <Typography variant="h3" mb={2}>
          {getPlantName(plantId)}
        </Typography>
        <DataContentWrapper
          showLoader={areMonthlyKpiTrendsLoading}
          isError={isMontlyKpisError}
          isRefetching={isMonthlyKpisRefetching}
          data={lastMonthKpis}
          retryFunction={() => void refetchMonthlyKpis()}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusDailyKpis kpiItems={kpiItems} gridItemXs={kpiGridItemXs} kpiSize="S" />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
