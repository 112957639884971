import {getTranslationKey} from '@hconnect/common/utils'
import {useNotification} from '@hconnect/uikit'
import {useQueryClient, useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../../shared/hooks'
import {QueryKeyName} from '../../../consts'

type DeleteCommentParameter = {
  id: string
  plantId: string
}

export const useDeleteShiftComment = () => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const axiosInstance = useAxios()
  const client = useQueryClient()
  const {notify} = useNotification()

  return useMutation<void, AxiosError, DeleteCommentParameter>(
    async ({id, plantId}) => {
      const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/shiftcomments/${id}`
      await axiosInstance.delete(path)
    },
    {
      onSuccess: () => {
        notify('success', t(getTranslationKey('comments.message.deleteSuccess', hrocPrefix)))
        void client.invalidateQueries([QueryKeyName.ShifthandoverComments])
      },
      onError: () => {
        notify('error', t(getTranslationKey('comments.message.failedToDeleteComment', hrocPrefix)))
      }
    }
  )
}
