import {PermissionType} from '@hconnect/apiclient'
import {PlantSelect} from '@hconnect/common/components'
import {PlantSelectOption} from '@hconnect/common/hproduce/types'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {generatePath, Navigate, useParams} from 'react-router'

import {useTranslationPrefix} from '../../../shared/hooks'
import {DataContentWrapper} from '../../components'
import {useHierarchyNavAccess} from '../../hooks'

type PathParams = {
  areaId: string
}

type AreatSelectProps = {
  path: string
  permission: PermissionType
  areaSelectPath: string
}

export const AreaPlantSelectByPermission = ({
  path,
  permission,
  areaSelectPath
}: AreatSelectProps) => {
  const {areaId} = useParams<PathParams>()
  if (!areaId) {
    throw new Error('Missing areaId param')
  }
  const {hrocPrefix} = useTranslationPrefix()
  const {isInitialLoading, isError, refetch, allowedAreas, getPlantsByAreaId} =
    useHierarchyNavAccess(permission)
  const plantsByArea = getPlantsByAreaId(areaId)

  const plantOptions: PlantSelectOption[] = plantsByArea.map(({id: plantId}) => {
    return {
      plantId,
      plantName: getPlantName(plantId),
      url: generatePath(path, {plantId})
    }
  })

  if (!isInitialLoading && !allowedAreas.includes(areaId)) {
    return <Navigate to={areaSelectPath} />
  }
  if (plantOptions.length === 1) {
    return <Navigate to={plantOptions[0].plantId} />
  }

  return (
    <PageContainer>
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={allowedAreas}
        isError={isError}
        retryFunction={() => void refetch()}
      >
        <PlantSelect plants={plantOptions} translationPrefix={hrocPrefix} />
      </DataContentWrapper>
    </PageContainer>
  )
}
