import {appUrl} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {SUMMARY_SEGMENT} from '@hconnect/common/hproduce/consts'
import {DateRange} from '@hconnect/common/types'
import {
  generateQueryString,
  getTimeRangeOrQuickSelectSlotsString,
  QuickSelectSlots
} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, SummaryScores, SummaryScore} from '../components'
import {useOptimizationSpecialistTimeRange, useEventsSummary} from '../hooks'
import {EventsSummary} from '../types'

type Props = {
  plantId: string
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

const getSummaryScoreItems = (
  prefix: string,
  t: TFunction,
  data?: EventsSummary
): SummaryScore[] => {
  return data
    ? [
        {
          label: t(`${prefix}.eventsSummary.label.general`),
          value: data.generalCount,
          valueVariant: 'neutral',
          showTooltip: false,
          dataTestId: 'events-general'
        },
        {
          label: t(`${prefix}.eventsSummary.label.production`),
          value: data.productionCount,
          valueVariant: 'neutral',
          showTooltip: false,
          dataTestId: 'events-production'
        },
        {
          label: t(`${prefix}.eventsSummary.label.maintenance`),
          value: data.maintenanceCount,
          valueVariant: 'neutral',
          showTooltip: false,
          dataTestId: 'events-maintenance'
        }
      ]
    : []
}

const generateCockpitShiftSummaryLink = (
  timeRange: DateRange | QuickSelectSlots,
  plantId: string
): string => {
  const query = {
    timeRange: getTimeRangeOrQuickSelectSlotsString(timeRange)
  }

  // TODO: extract all external app link generation to a separate file
  const baseUrl = appUrl(COCKPIT_HOST)
  const path = generatePath(`/:plantId/${SUMMARY_SEGMENT}`, {plantId})
  const endpoint = `${baseUrl}${path}`
  const linkPath = `${endpoint}?${generateQueryString(query)}`

  return linkPath
}

export const EventsContainer: React.FC<Props> = ({plantId, cardBoxSx, placeholderWrapperSx}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {start, end, rawTimeRange} = useOptimizationSpecialistTimeRange()

  const {data, isInitialLoading, isError, isRefetching, refetch} = useEventsSummary({
    plantId,
    startDate: start.toISOString(),
    endDate: end.toISOString()
  })

  const linkPath = generateCockpitShiftSummaryLink(rawTimeRange, plantId)
  const items = getSummaryScoreItems(hrocPrefix, t, data)
  return (
    <CardLinkWrapper href={linkPath} data-test-id="events-summary-card-link-to-shifthandover">
      <CardBox sx={cardBoxSx} data-test-id="events-summary-card">
        <Box display="flex" flexDirection="row">
          <Typography variant="h3" mb={2}>
            {t(`${hrocPrefix}.eventsSummary.cardTitle`)}
          </Typography>
        </Box>
        <DataContentWrapper
          showLoader={isInitialLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSx}
        >
          <SummaryScores items={items} />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
