import {PlantMapData, useMapCoordinates} from '../../hooks'
import {Bounds, MapAreaConfig} from '../../types'

import {AreaMap} from './AreaMap'
import {AreaRegionOverlay} from './AreaRegionOverlay'
import {BordersOverlay} from './BordersOverlay'
import {PerformanceMapMarker} from './PerformanceMapMarker'

type AreaKilnStatusMapProps = {
  width: number
  height: number
  plantMapData: PlantMapData[]
  mapAreaConfig: MapAreaConfig
  displayBounds: Bounds
  onPlantMarkerClick: (plantId: string) => void
}

export const AreaKilnStatusMap: React.FC<AreaKilnStatusMapProps> = ({
  width,
  height,
  mapAreaConfig,
  plantMapData,
  displayBounds,
  onPlantMarkerClick
}) => {
  const {translate, scale, getMarkerPosition} = useMapCoordinates({
    priorityBounds: mapAreaConfig.priorityBounds,
    plantLocationMap: mapAreaConfig.plantLocationMap,
    displayBounds
  })

  return (
    <AreaMap width={width} height={height}>
      {/* Order matters */}
      <AreaRegionOverlay
        width={width}
        height={height}
        scale={scale}
        translate={translate}
        subRegions={mapAreaConfig.subRegions}
        data={plantMapData}
      />
      <BordersOverlay
        bordersPaths={mapAreaConfig.bordersPaths}
        width={width}
        height={height}
        scale={scale}
        translate={translate}
      />
      {plantMapData?.map(({equipments, pxTrendMetaData, plantId, plantName}) => (
        <PerformanceMapMarker
          key={plantId}
          equipments={equipments}
          pxTrendMetaData={pxTrendMetaData}
          plantName={plantName}
          coordinate={getMarkerPosition(plantId)}
          onClick={() => onPlantMarkerClick(plantId)}
          dataTestId={`plant-marker-${plantId}`}
        />
      ))}
    </AreaMap>
  )
}
