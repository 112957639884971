import type {Permission} from '@hconnect/apiclient'

export const hasChecklistsPermission = (permissions: Permission[]): boolean =>
  permissions.some(
    (p) => p.permissionType === 'VIEW_CHECKLISTS' || p.permissionType === 'CHANGE_CHECKLISTS'
  )

export const hasViewReliabilityEngineerDashboardPermission = (permissions: Permission[]): boolean =>
  permissions.some((p) => p.permissionType === 'VIEW_RELIABILITY_ENGINEER_DASHBOARD')

export const hasViewProcessEngineerDashboardPermission = (permissions: Permission[]): boolean =>
  permissions.some((p) => p.permissionType === 'VIEW_PROCESS_ENGINEER_DASHBOARD')

export const hasViewOptimizationSpecialistDashboardPermission = (
  permissions: Permission[]
): boolean => permissions.some((p) => p.permissionType === 'VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD')

export const hasRcfaCompliancePagePermission = (permissions: Permission[]): boolean =>
  permissions.some((p) => p.permissionType === 'CONTRIBUTE_TO_ROOT_CAUSE_FAILURE_ANALYSES')
