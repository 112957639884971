import React from 'react'

type FadeoutMaskDefProps = {
  width: number
  height: number
  scale: number
}

const FADE_MASK_ID = 'topFadeMask'
const FADE_GRADIENT_ID = 'topFadeGradient'

export const FadeoutMaskDef = ({width, height, scale}: FadeoutMaskDefProps) => {
  return (
    <mask id={FADE_MASK_ID}>
      <linearGradient
        id={FADE_GRADIENT_ID}
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="0"
        x2="0"
        y2={height}
      >
        <stop offset="0%" stopColor="white" stopOpacity="0" />
        <stop offset="15%" stopColor="white" stopOpacity="1" />
        <stop offset="100%" stopColor="white" stopOpacity="1" />
      </linearGradient>
      <rect width={width / scale} height={height / scale} fill={`url(#${FADE_GRADIENT_ID})`} />
    </mask>
  )
}

type FadeoutMaskProps = {
  children: React.ReactNode
}

export const FadeoutMask = ({children}: FadeoutMaskProps) => {
  return <g mask={`url(#${FADE_MASK_ID})`}>{children}</g>
}
