import {KpiRangeBreachSeverity} from '@hconnect/common/types'

type KpiValueDto = {
  planned: number
  actual: number
  delta: number
}

export type MonthlyAggregatedPlantProductionKpisDto = {
  year: number
  /** The number of the month: {1,2,3,…,12} */
  month: number
  monthlyAggregatedActualProduction: {
    cementProduction: number
    clinkerProduction: number
  }
  monthToMonthKpiValues?: {
    weightedKilnOperatingCoefficient: KpiValueDto
    specificKilnHeatConsumption: KpiValueDto
    alternativeFuelRate: KpiValueDto
    technicalCementPowerConsumptionKWht: KpiValueDto
    specificCo2EmissionPerTonOfClinker: KpiValueDto
    specificCo2EmissionPerTonOfCement: KpiValueDto
    kilnReliabilityCoefficient: KpiValueDto
    meanTimeBetweenFailure: KpiValueDto
  }
}

export type KpiDailyData = {
  key: string
  label: string
  value?: string
  unit?: string
  kpiBreachSeverity?: KpiRangeBreachSeverity | null
}

export type KpiTrendChartData = {
  labels: string[]
  data: number[]
}

export type KpiItem = KpiDailyData & {
  monthlyTrendData?: KpiTrendChartData
  monthlyTrendDataUnit?: string
}

export type PlantStatusMontlyKpiTrends = {
  clinkerProduction: KpiTrendChartData
  cementProduction: KpiTrendChartData
  meanTimeBetweenFailure: KpiTrendChartData
  kilnOperatingCoefficient: KpiTrendChartData
  alternativeFuelRate: KpiTrendChartData
  kilnReliabilityCoefficient: KpiTrendChartData
}

export type ReliabilityDailyKpis = {
  cementProduction?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
  clinkerProduction?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
  alternativeFuelRate?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
  kilnMTBF?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
}
