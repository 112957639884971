import React, {FC} from 'react'

import {BordersPath, Point} from '../../types'

import {CountryBorders} from './CountryBorders'
import {FadeoutMask, FadeoutMaskDef} from './FadeoutMask'
import {ResponsiveOverlay, ResponsiveOverlayContent} from './ResponsiveOverlay'

type BordersOverlayProps = {
  width: number
  height: number
  translate: Point
  scale: number
  bordersPaths: BordersPath[]
}

export const BordersOverlay: FC<BordersOverlayProps> = ({
  width,
  height,
  scale,
  translate,
  bordersPaths
}) => {
  return (
    <ResponsiveOverlay width={width} height={height}>
      <defs>
        <FadeoutMaskDef width={width} height={height} scale={scale} />
      </defs>
      <ResponsiveOverlayContent scale={scale} translate={translate}>
        <FadeoutMask>
          {bordersPaths.map(({id, path}) => (
            <CountryBorders key={id} path={path} />
          ))}
        </FadeoutMask>
      </ResponsiveOverlayContent>
    </ResponsiveOverlay>
  )
}
