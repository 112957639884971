import {ShiftEventCommentDto} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {useNotification} from '@hconnect/uikit'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {ApiPath} from '../../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../../shared/hooks'
import {QueryKeyName} from '../../../consts'

type Props = {
  plantId: string
  startDate: string
  endDate: string
}

export const useGetShiftComments = <TSelect = ShiftEventCommentDto[]>(
  {plantId, startDate, endDate}: Props,
  options?: UseQueryOptions<ShiftEventCommentDto[], AxiosError, TSelect>
) => {
  const {t} = useTranslation()
  const axiosInstance = useAxios()
  const {hrocPrefix} = useTranslationPrefix()
  const {notify} = useNotification()

  return useQuery<ShiftEventCommentDto[], AxiosError, TSelect>({
    queryKey: [QueryKeyName.ShifthandoverComments, plantId, startDate, endDate],
    queryFn: async () => {
      const response = await axiosInstance.get<ShiftEventCommentDto[]>(
        `${ApiPath.SHIFT_HANDOVER}/${plantId}/shiftcomments`,
        {params: {startDate, endDate}}
      )

      return response.data
    },
    onError: () => {
      notify('error', t(getTranslationKey('comments.message.failedToFetchComments', hrocPrefix)))
    },
    ...options
  })
}
